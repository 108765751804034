import gql from "graphql-tag";

export const ProductIntermediateStatusAssignments = {
    Queries: {
        ProductIntermediateStatusAssignments: gql`
        query productIntermediateStatusAssignments($productIntermediateStatusId: ID, $productStatusAssignmentId: ID, $productIntermediateStatusAssignmentStatusIds: [ID]) {
            productIntermediateStatusAssignments(productIntermediateStatusId: $productIntermediateStatusId, productStatusAssignmentId: $productStatusAssignmentId, productIntermediateStatusAssignmentStatusIds: $productIntermediateStatusAssignmentStatusIds) {
                Id
                ProductIntermediateStatusId
                Note
                CreatedAt
                CreatedBy
                IsDone
                ProductIntermediateStatusAssignmentStatusId
                ProductStatusAssignmentId
            }
        }`,
    },
    Mutations: {
        SaveProductIntermediateStatusAssignment: gql`
        mutation saveProductIntermediateStatusAssignment($productIntermediateStatusAssignment: ProductIntermediateStatusAssignmentsInput) {
            saveProductIntermediateStatusAssignment(productIntermediateStatusAssignment: $productIntermediateStatusAssignment) {
                IsSuccess
                Message
            }
        }`,
    }
};
