const {
  NewsletterProducts,
} = require("@/graphql/newsletterProducts/NewsletterProducts.ts");

class NewsletterProductsComponent {
  async getNewsletterProducts(apollo, newsletterId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!newsletterId) {
        return undefined;
      }
      const newsletterProducts = await apollo
        .query({
          query: NewsletterProducts.Queries.NewsletterProducts,
          variables: {
            newsletterId: newsletterId,
          },
        })
        .then(({ data }) => data?.newsletterProducts)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!newsletterProducts) {
        return undefined;
      }
      return newsletterProducts;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = NewsletterProductsComponent;
