<template lang="pug">
  b-card
    .row
      .col-md-2.col-sm-12
        p
          font-awesome-icon.mr-2(
            :icon="['fas', 'circle']"
            :class="{ 'red': perc == 0, 'yellow': perc > 0 && perc <= 33.4, 'orange': perc > 33.4 }"
          )
          router-link(:to="{ name: 'ProductDetails', params: { productId: product.Id, hostId: product.HostId } }", target="_blank")
            b {{ product.Id }}: {{ product.Name1 }}

      .col-md-4.col-sm-12
        p(v-if="productStatus && productStatusAssignment && user")
          font-awesome-icon.mr-2(:icon="['fas', 'pen']" v-if="productStatus.Id == ProductStatusEnum.InProgress")
          font-awesome-icon.mr-2(:icon="['fas', 'magnifying-glass']" v-else-if="productStatus.Id == ProductStatusEnum.InControl")
          font-awesome-icon.mr-2(:icon="['fas', 'check']" v-else-if="productStatus.Id == ProductStatusEnum.Completed")
          span {{ productStatus.Description }}
          font-awesome-icon.mr-2.ml-2(:icon="['fas', 'user']")
          span {{ user.FirstName }} {{ user.LastName }}
          font-awesome-icon.mr-2.ml-2(:icon="['fas', 'clock']")
          span {{ productStatusAssignment.CreatedAt | formatDateTime }}
        p(v-else) lädt...

      .col-md-6.col-sm-12
        host-editing-progress-card-product-intermediate-status(
          v-for="_productIntermediateStatus in productIntermediateStatus"
          :product-intermediate-status="_productIntermediateStatus"
          :productIntermediateStatusAssignments="productIntermediateStatusAssignments"
        )

</template>

<script>
import { ProductStatusAssignmentsComponent } from "@/lib/components/productStatusAssignments/ProductStatusAssignmentsComponent.ts";
import HostEditingProgressCardProductIntermediateStatusAssignment from "@/views/host/hostEditing/HostEditingProgressCardProductIntermediateStatusAssignment.vue";
import { ProductStatusComponent } from "@/lib/components/productStatus/ProductStatusComponent.ts";
import { UsersComponent } from "@/lib/components/users/UsersComponent.ts";
import { ProductStatusEnum } from "@/utils/enums/productStatus/ProductStatusEnum.ts";
import { ProductIntermediateStatusAssignmentsComponent } from "@/lib/components/productIntermediateStatusAssignments/ProductIntermediateStatusAssignmentsComponent.ts";
import { ProductIntermediateStatusComponent } from "@/lib/components/productIntermediateStatus/ProductIntermediateStatusComponent.ts";
import HostEditingProgressCardProductIntermediateStatus from "@/views/host/hostEditing/HostEditingProgressCardProductIntermediateStatus.vue";
import { ProductIntermediateStatusAssignmentStatusEnum } from "@/utils/enums/productIntermediateStatusAssignmentStatus/productIntermediateStatusAssignmentStatusEnum.ts";

export default {
  name: "HostEditingProgressCardProduct",
  components: {
    HostEditingProgressCardProductIntermediateStatus,
    HostEditingProgressCardProductIntermediateStatusAssignment,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      productStatus: undefined,
      productStatusAssignment: undefined,
      user: undefined,
      productIntermediateStatus: [],
      productIntermediateStatusAssignments: [],
      perc: 0,

      ProductStatusEnum: ProductStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadProductStatus();
      this.loadProductStatusAssignment();
      this.loadProductIntermediateStatus();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadProductStatus() {
      try {
        const productStatusComponent = new ProductStatusComponent();
        const productStatus = await productStatusComponent.getProductStatusById(
          this.$apollo,
          this.product.ProductStatusId
        );
        if (!productStatus) {
          return;
        }
        this.productStatus = productStatus;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProductStatusAssignment() {
      try {
        const productStatusAssignmentsComponent =
          new ProductStatusAssignmentsComponent();
        const productStatusAssignment =
          await productStatusAssignmentsComponent.getProductStatusAssignment(
            this.$apollo,
            this.product.Id,
            this.product.ProductStatusId,
            false
          );
        if (!productStatusAssignment) {
          return;
        }
        this.productStatusAssignment = productStatusAssignment;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadUser() {
      try {
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
          this.$apollo,
          this.productStatusAssignment.CreatedBy
        );
        if (!user) {
          return;
        }
        this.user = user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProductIntermediateStatus() {
      try {
        const productIntermediateStatusComponent =
          new ProductIntermediateStatusComponent();
        const productIntermediateStatus =
          await productIntermediateStatusComponent.getProductIntermediateStatus(
            this.$apollo,
            this.product.ProductStatusId,
            true
          );
        if (!productIntermediateStatus) {
          return;
        }
        this.productIntermediateStatus = productIntermediateStatus;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async loadProductIntermediateStatusAssignments() {
      try {
        const productIntermediateStatusAssignmentsComponent =
          new ProductIntermediateStatusAssignmentsComponent();
        const productIntermediateStatusAssignments =
          await productIntermediateStatusAssignmentsComponent.getProductIntermediateStatusAssignments(
            this.$apollo,
            this.productStatusAssignment.Id,
            undefined,
            [
              ProductIntermediateStatusAssignmentStatusEnum.Draft,
              ProductIntermediateStatusAssignmentStatusEnum.InProgress,
              ProductIntermediateStatusAssignmentStatusEnum.Completed,
            ]
          );
        if (!productIntermediateStatusAssignments) {
          return;
        }
        this.productIntermediateStatusAssignments =
          productIntermediateStatusAssignments;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    productStatusAssignment() {
      try {
        this.loadUser();
        this.loadProductIntermediateStatusAssignments();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    productIntermediateStatusAssignments() {
      try {
        if (
          this.productIntermediateStatusAssignments &&
          Array.isArray(this.productIntermediateStatusAssignments) &&
          this.productIntermediateStatusAssignments.length
        ) {
          const total = this.productIntermediateStatusAssignments.length;
          const done = this.productIntermediateStatusAssignments.filter(
            (p) => p.IsDone
          ).length;
          this.perc = (done / total) * 100;
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}

.yellow {
  color: yellow;
}

.orange {
  color: orange;
}
</style>
