<template lang="pug">
  .table-responsive
    table.table.table-bordered
      thead
        tr
          th Id
          th Beschreibung
          th Typ
          th Erstellt am
          th Aktualisiert am
          th(v-if="services && Array.isArray(services) && services.length && services.some((s) => s.ServiceStatusId == ServiceStatusEnum.Draft)")
          th
      tbody
        services-table-row(
          v-for="service in services"
          :service="service"
          :load-services="loadServices"
          :load-services-count="loadServicesCount"
        )

</template>

<script>
import ServicesTableRow from "@/views/services/ServicesTableRow.vue";
import { ServiceStatusEnum } from "@/utils/enums/serviceStatus/ServiceStatusEnum.ts";

export default {
  name: "ServicesTable",
  components: { ServicesTableRow },
  props: {
    services: {
      type: Array,
      required: true,
    },
    loadServices: {
      type: Function,
      required: false,
    },
    loadServicesCount: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      ServiceStatusEnum: ServiceStatusEnum,
    };
  },
};
</script>

<style scoped></style>
