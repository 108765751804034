// @ts-ignore
import {NotificationComments} from "../../../graphql/notificationComments/NotificationComments.ts";

export class NotificationCommentsComponent {
    async createNotificationComment(apollo, notificationComment) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!notificationComment) {
                return {
                    IsSuccess: false,
                    Message: "Kein Benachrichtigungskommentar gefunden.",
                };
            }
            const createdNotificationComment = await apollo.mutate({
                mutation: NotificationComments.Mutations.CreateNotificationComment,
                fetchPolicy: "no-cache",
                variables: {
                    notificationComment: notificationComment,
                },
            })
                .then(({data}) => data?.createNotificationComment)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!createdNotificationComment) {
                return {
                    IsSuccess: false,
                    Message: "Der Benachrichtigungskommtar konnte nicht erstellt werden.",
                };
            }
            return createdNotificationComment;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getNotificationComments(apollo, notificationId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!notificationId) {
                return undefined;
            }
            const notificationComments = await apollo.query({
                query: NotificationComments.Queries.NotificationComments,
                fetchPolicy: "no-cache",
                variables: {
                    notificationId: notificationId,
                },
            })
                .then(({data}) => data?.notificationComments)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!notificationComments) {
                return undefined;
            }
            return notificationComments;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
};
