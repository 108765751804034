import gql from "graphql-tag";

export const Addresses = {
    Queries: {
        AddressesByEmail: gql`
        query addressesByEmail($email: String) {
            addressesByEmail(email: $email) {
                Id
                Guid
                FirstName
                LastName
                FormOfAddress
                Title
                Company
                Street
                Street2
                PostalCode
                City
                Country
                Phone
                Email
            }
        }`,
        Address: gql`
        query address($id: ID) {
            address(id: $id) {
                Id
                Guid
                FirstName
                LastName
                FormOfAddress
                Title
                Company
                Street
                Street2
                PostalCode
                City
                Country
                Phone
                Email
            }
        }`,
        Addresses: gql`
        query addresses($filter: String, $offset: Int, $limit: Int) {
            addresses(filter: $filter, offset: $offset, limit: $limit) {
                Id
                Guid
                FirstName
                LastName
                FormOfAddress
                Title
                Company
                Street
                Street2
                PostalCode
                City
                Country
                Phone
                Email
            }
        }`,
    },
    Mutations: {
        SaveAddress: gql`
        mutation saveAddress($address: AddressesInput) {
            saveAddress(address: $address) {
                IsSuccess
                Message
                StringData
            }
        }`,
    }
};
