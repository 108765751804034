import gql from "graphql-tag";

export const UserGroupUsers = {
    Queries: {
        UserGroupUsers: gql`
        query userGroupUsers($userGroupId: ID) {
            userGroupUsers(userGroupId: $userGroupId) {
                Id
                UserGroupId
                UserId
                CreatedBy
                CreatedAt
            }
        }`,
    },
    Mutations: {
        CreateUserGroupUser: gql`
        mutation createUserGroupUser($userGroupUser: UserGroupUsersInput) {
            createUserGroupUser(userGroupUser: $userGroupUser) {
                IsSuccess
                Message
            }
        }`,
        DeleteUserGroupUser: gql`
        mutation deleteUserGroupUser($id: ID) {
            deleteUserGroupUser(id: $id) {
                IsSuccess
                Message
            }
        }`,
    },
};
