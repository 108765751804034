import moment from "moment";
const s3cloudCDN = "s3-cloud.td-cdn.de";

let VueFilter = {
  formatInputDateFilter: {
    name: "formatInputDate",
    func: (value) => {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD");
      }
    },
  },
  formatInputDateTimeFilter: {
    name: "formatInputDateTime",
    func: (value) => {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
  },
  formatDateFilter: {
    name: "formatDate",
    func: (value) => {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY");
      }
    },
  },
  formatDateTimeFilter: {
    name: "formatDateTime",
    func: (value) => {
      if (value) {
        return moment(String(value)).format("DD.MM.YYYY HH:mm:ss");
      }
    },
  },
  formatEuroFilter: {
    name: "formatEuro",
    func: (value) => {
      if (!value) {
        return "0,00 €";
      }
      return `${parseFloat(parseFloat(value).toFixed(2)).toLocaleString(
        "de-DE",
        { minimumFractionDigits: 2 }
      )} €`;
    },
  },
  formatValueFilter: {
    name: "formatValue",
    func: (value) => {
      if (!value) {
        return "0,00";
      }
      return `${parseFloat(value).toFixed(2).toString().replace(".", ",")}`;
    },
  },
  formatPercentageFilter: {
    name: "formatPercentage",
    func: (value) => {
      if (!value) {
        return "0,00 %";
      }
      return `${parseFloat(value).toFixed(2).toString().replace(".", ",")} %`;
    },
  },
  formatCDNFilter: {
    name: "formatCDN",
    func: (value) => {
      if (!value) {
        return "touridat.com";
      }
      const cdn = new URL(value); // https://s3-cloud.td-cdn.de/ImageManagement/33873/Hausansicht.jpg
      return `${cdn.protocol}//${s3cloudCDN}${cdn.pathname}`;
    },
  },
};
export default VueFilter;
