export const AdvertisingTypesEnum = {
    RegularNewsletter: 1,
    Facebook: 2,
    EbayKleinanzeigen: 3,
    Ebay: 4,
    Slider: 5,
    Twitter: 7,
    MarktDe: 8,
    NettoUrlaub: 9,
    BestSeller: 11,
    TouriTipps: 12,
    TouriPics: 13,
    MondayNewsletter: 20,
    ThursdayNewsletter: 21,
    QuarterNewsletter: 22,
    FeWoNewsletter: 23,
    ActionNewsletter: 24,
    RecommendationNewsletter: 25,
    TouriTippsNewsletter: 26,
    DealSale: 27,
    Reisereporter: 28,
    WhatsApp: 35,
}
