// @ts-ignore
import {Services} from "../../../graphql/services/Services.ts";

export class ServicesComponent {
    async getService(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const service = await apollo.query({
                query: Services.Queries.Service,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.service)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!service) {
                return undefined;
            }
            return service;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getServices(apollo, serviceStatus, filter, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const services = await apollo.query({
                query: Services.Queries.Services,
                fetchPolicy: "no-cache",
                variables: {
                    serviceStatus: serviceStatus,
                    filter: filter,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.services)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!services) {
                return undefined;
            }
            return services;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getServicesCount(apollo, serviceStatus, filter) {
        try {
            if (!apollo) {
                return undefined;
            }
            const servicesCount = await apollo.query({
                query: Services.Queries.ServicesCount,
                fetchPolicy: "no-cache",
                variables: {
                    serviceStatus: serviceStatus,
                    filter: filter,
                },
            })
                .then(({data}) => data?.servicesCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (servicesCount == undefined) {
                return undefined;
            }
            return servicesCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveService(apollo, service) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!service) {
                return {
                    IsSuccess: false,
                    Message: "Keine Leistung gefunden.",
                };
            }
            const savedService = await apollo.mutate({
                mutation: Services.Mutations.SaveService,
                fetchPolicy: "no-cache",
                variables: {
                    service: service,
                },
            })
                .then(({data}) => data?.saveService)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedService) {
                return {
                    IsSuccess: false,
                    Message: "Die Leistung konnte nicht gespeichert werden.",
                };
            }
            return savedService;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
