import VueSimpleAlert from "vue-simple-alert";
import VueSweetalert2 from "vue-sweetalert2";

export const vuePluginsUtils = [
  {
    plugin: VueSimpleAlert,
    config: {
      customClass: {
        confirmButton: "button button-primary button-tdays",
        cancelButton: "button button-primary button-tpics ml-3",
        popup: "alert-popup",
      },
      cancelButtonText: "Nein",
      buttonsStyling: false,
    },
  },
  {
    plugin: VueSweetalert2,
    config: {
      customClass: {
        confirmButton: "button button-primary button-tdays",
        cancelButton: "button button-primary button-tpics ml-3",
        popup: "alert-popup",
      },
      timer: 1500,
      cancelButtonText: "Abbrechen",
      buttonsStyling: false,
    },
  },
];
