<template lang="pug">
  .moloss-category-search-select-list-results-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'layer-group']")
    span {{ molossCategory.id }}: {{ molossCategory.Name }} {{ categoryUrl ? `<${categoryUrl.UrlPath}>` : '' }}

</template>

<script>
export default {
  name: "MolossCategorySearchSelectListResultsListItem",
  props: {
    molossCategory: {
      type: Object,
      required: true,
    },
  },
  computed: {
    categoryUrl() {
      try {
        return this.molossCategory.CategoryUrls.find(
          (c) => c.SystemLanguageId == 1
        );
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
