// @ts-ignore
import {ProductIntermediateStatusAssignments} from "../../../graphql/productIntermediateStatusAssignments/ProductIntermediateStatusAssignments.ts"

export class ProductIntermediateStatusAssignmentsComponent {
  async getProductIntermediateStatusAssignments(apollo, productStatusAssignmentId, productIntermediateStatusId, productIntermediateStatusAssignmentStatusIds) {
    try {
      if (!apollo) {
        return undefined;
      }
      const productIntermediateStatusAssignments = await apollo.query({
        query: ProductIntermediateStatusAssignments.Queries.ProductIntermediateStatusAssignments,
        fetchPolicy: "no-cache",
        variables: {
          productStatusAssignmentId: productStatusAssignmentId,
          productIntermediateStatusId: productIntermediateStatusId,
          productIntermediateStatusAssignmentStatusIds: productIntermediateStatusAssignmentStatusIds,
        },
      })
          .then(({data}) => data?.productIntermediateStatusAssignments)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!productIntermediateStatusAssignments) {
        return undefined;
      }
      return productIntermediateStatusAssignments;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async saveProductIntermediateStatusAssignment(
    apollo,
    productIntermediateStatusAssignment
  ) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: `Kein Apollo-Client gefunden.`,
        };
      }
      if (!productIntermediateStatusAssignment) {
        return {
          IsSuccess: false,
          Message: `Keine Zwischenstatuszuordung gefunden.`,
        };
      }
      let savedProductIntermediateStatusAssignment = await apollo
        .mutate({
          mutation:
            ProductIntermediateStatusAssignments.Mutations
              .SaveProductIntermediateStatusAssignment,
          fetchPolicy: "no-cache",
          variables: {
            productIntermediateStatusAssignment:
              productIntermediateStatusAssignment,
          },
        })
        .then(({ data }) => data?.saveProductIntermediateStatusAssignment)
        .catch((e) => {
          console.log(e);
          return undefined;
        });
      if (!savedProductIntermediateStatusAssignment) {
        return {
          IsSuccess: false,
          Message: "Der Status konnte nicht gespeichert werden.",
        };
      }
      if (!savedProductIntermediateStatusAssignment.IsSuccess) {
        return {
          IsSuccess: false,
          Message: savedProductIntermediateStatusAssignment.Message,
        };
      }
      return {
        IsSuccess: true,
      };
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}
