// @ts-ignore
import {Orders} from "../../../graphql/orders/Orders.ts";

export class OrdersComponent {
    async getOrder(apollo, orderId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!orderId) {
                return undefined;
            }
            const order = await apollo
                .query({
                    query: Orders.Queries.Order,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: orderId,
                    },
                })
                .then(({data}) => data?.order)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!order) {
                return undefined;
            }
            return order;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getOrders(apollo, filter, from, to, paymentStatusCodes, orderStatusCodes, platformIds, referrerIds, transactionTypeIds, shippingTypeIds, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const orders = await apollo
                .query({
                    query: Orders.Queries.Orders,
                    fetchPolicy: "no-cache",
                    variables: {
                        filter: filter,
                        from: from,
                        to: to,
                        paymentStatusCodes: paymentStatusCodes,
                        orderStatusCodes: orderStatusCodes,
                        platformIds: platformIds,
                        referrerIds: referrerIds,
                        transactionTypeIds: transactionTypeIds,
                        shippingTypeIds: shippingTypeIds,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({data}) => data?.orders)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!orders) {
                return undefined;
            }
            return orders;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getOrdersCount(apollo, filter, from, to, paymentStatusCodes, orderStatusCodes, platformIds, referrerIds, transactionTypeIds, shippingTypeIds) {
        try {
            if (!apollo) {
                return undefined;
            }
            const ordersCount = await apollo
                .query({
                    query: Orders.Queries.OrdersCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        filter: filter,
                        from: from,
                        to: to,
                        paymentStatusCodes: paymentStatusCodes,
                        orderStatusCodes: orderStatusCodes,
                        platformIds: platformIds,
                        referrerIds: referrerIds,
                        transactionTypeIds: transactionTypeIds,
                        shippingTypeIds: shippingTypeIds,
                    },
                })
                .then(({data}) => data?.ordersCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (ordersCount == undefined) {
                return undefined;
            }
            return ordersCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async refundOrder(apollo, orderId, userId, refundAmount) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Keinen Apollo-Client gefunden.`,
                };
            }
            if (!orderId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Auftrags-Id gefunden.`,
                };
            }
            if (refundAmount == undefined) {
                return {
                    IsSuccess: false,
                    Message: `Keinen Erstattungsbetrag gefunden.`,
                };
            }
            const refundedOrder = apollo
                .mutate({
                    mutation: Orders.Mutations.RefundOrder,
                    fetchPolicy: "no-cache",
                    variables: {
                        orderId: orderId,
                        userId: userId,
                        refundAmount: refundAmount,
                    },
                })
                .then(({data}) => data?.refundOrder)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!refundedOrder) {
                return {
                    IsSuccess: false,
                    Message: `Der Auftrag konnte nicht zurückerstattet werden.`,
                };
            }
            return refundedOrder;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getOrderByOrderNumber(apollo, orderNumber) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!orderNumber) {
                return undefined;
            }
            const order = await apollo.query({
                query: Orders.Queries.OrderByOrderNumber,
                fetchPolicy: "no-cache",
                variables: {
                    orderNumber: orderNumber,
                },
            })
                .then(({data}) => data?.orderByOrderNumber)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!order) {
                return undefined;
            }
            return order;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getOrderByGuid(apollo, guid) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!guid) {
                return undefined;
            }
            const order = await apollo.query({
                query: Orders.Queries.OrderByGuid,
                fetchPolicy: "no-cache",
                variables: {
                    guid: guid,
                },
            })
                .then(({data}) => data?.orderByGuid)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!order) {
                return undefined;
            }
            return order;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveOrder(apollo, order, items, shopVoucher, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!order) {
                return {
                    IsSuccess: false,
                    Message: "Keinen Auftrag gefunden.",
                };
            }
            if (!items) {
                return {
                    IsSuccess: false,
                    Message: "Keine Auftragsartikel gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const savedOrder = await apollo.mutate({
                mutation: Orders.Mutations.SaveOrder,
                fetchPolicy: "no-cache",
                variables: {
                    order: order,
                    items: items,
                    shopVoucher: shopVoucher,
                    userId: userId,
                },
            })
                .then(({data}) => data?.saveOrder)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedOrder) {
                return {
                    IsSuccess: false,
                    Message: "Der Auftrag konnte nicht gespeichert werden.",
                };
            }
            return savedOrder;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
