<template lang="pug">
apexchart(type="area" height="500" :options="chartOptions" :series="series")
</template>

<script>
import { ChartComponent } from "@/lib/components/chart/ChartComponent.ts";

export default {
  name: "LineChart",
  props: {
    // Object with 'name' and 'items'
    options: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: false,
    },
    displayUnit: {
      type: String,
      required: true,
    },
    from: {
      type: Date,
      required: true,
    },
    to: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "date",
          categories: [],
        },
        tooltip: {
          x: {
            format: "DD.MM.YYYY", //'dd.MM.yyyy HH:mm'
          },
        },
      },
    };
  },
  watch: {
    items() {
      try {
        if (!this.items) {
          return;
        }
        const chartComponent = new ChartComponent();
        this.series = chartComponent.getLineChartSeriesByItems(
          this.items,
          this.from,
          this.to,
          this.displayUnit,
          "Gesamtumsatz",
          true
        );
        const categories = chartComponent.getLineChartLabels(
          this.from,
          this.to,
          this.displayUnit
        );
        this.chartOptions = {
          chart: {
            height: 350,
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            categories: categories,
          },
        };
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    options() {
      try {
        if (!this.options) {
          return;
        }
        const chartComponent = new ChartComponent();
        let series = [];
        for (const option of this.options) {
          const _series = chartComponent.getLineChartSeriesByItems(
            option.items,
            this.from,
            this.to,
            this.displayUnit,
            option.name,
            false
          );
          series = series.concat(_series);
        }
        const categories = chartComponent.getLineChartLabels(
          this.from,
          this.to,
          this.displayUnit
        );
        this.series = series;
        this.chartOptions = {
          chart: {
            height: 350,
            type: "area",
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            categories: categories,
          },
        };
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
