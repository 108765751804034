import gql from "graphql-tag";

export const Mails = {
    Queries: {
        Mail: gql`
        query mail($id: ID) {
            mail(id: $id) {
                Id
                Guid
                MailboxId
                MailTypeId
                MailboxFolderId
                HostId
                MailFrom
                MailSender
                MailTo
                MailCc
                MailBcc
                ReplyTo
                ReturnPath
                SentAt
                ReceivedAt
                ContentType
                Importance
                Priority
                MessageId
                InReplyToMessageIds
                ReferenceMessageIds
                Header
                Subject
                BodyHtmlText
                BodyPlainText
                Note
                ReplyToMailId
                ForwardOfMailId
                SeenAt
                SeenBy
                LastSeenAt
                LastSeenBy
                DeletedAt
                DeletedBy
                LastSendAttemptAt
                LastSendAttemptErrorMessage
                OldMailId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                OrderId
                IsReadNotificationRequested
            }
        }`,
        Mails: gql`
        query mails($mailboxId: ID, $mailboxFolderId: ID, $mailTypeId: ID, $orderId: ID, $hostId: ID, $filter: String, $mailFrom: String, $mailTo: String, $receivedFrom: Date, $receivedTo: Date, $isDeleted: Boolean, $isSeen: Boolean, $seenBy: ID $offset: Int, $limit: Int) {
            mails(mailboxId: $mailboxId, mailboxFolderId: $mailboxFolderId, mailTypeId: $mailTypeId, orderId: $orderId, hostId: $hostId, filter: $filter, mailFrom: $mailFrom, mailTo: $mailTo, receivedFrom: $receivedFrom, receivedTo: $receivedTo, isDeleted: $isDeleted, isSeen: $isSeen, seenBy: $seenBy, offset: $offset, limit: $limit) {
                Id
                Guid
                MailboxId
                MailTypeId
                MailboxFolderId
                HostId
                MailFrom
                MailSender
                MailTo
                MailCc
                MailBcc
                ReplyTo
                ReturnPath
                SentAt
                ReceivedAt
                ContentType
                Importance
                Priority
                MessageId
                InReplyToMessageIds
                ReferenceMessageIds
                Header
                Subject
                BodyHtmlText
                BodyPlainText
                Note
                ReplyToMailId
                ForwardOfMailId
                SeenAt
                SeenBy
                LastSeenAt
                LastSeenBy
                DeletedAt
                DeletedBy
                LastSendAttemptAt
                LastSendAttemptErrorMessage
                OldMailId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                OrderId
                IsReadNotificationRequested
            }
        }`,
        MailsCount: gql`
        query mailsCount($mailboxId: ID, $mailboxFolderId: ID, $mailTypeId: ID, $orderId: ID, $hostId: ID, $filter: String, $mailFrom: String, $mailTo: String, $receivedFrom: Date, $receivedTo: Date, $isDeleted: Boolean, $isSeen: Boolean, $seenBy: ID) {
            mailsCount(mailboxId: $mailboxId, mailboxFolderId: $mailboxFolderId, mailTypeId: $mailTypeId, orderId: $orderId, hostId: $hostId, filter: $filter, mailFrom: $mailFrom, mailTo: $mailTo, receivedFrom: $receivedFrom, receivedTo: $receivedTo, isDeleted: $isDeleted, isSeen: $isSeen, seenBy: $seenBy)
        }`,
        MailHtml: gql`
        query mailHtml($userId: ID, $mailId: ID, $mailboxId: ID) {
            mailHtml(userId: $userId, mailId: $mailId, mailboxId: $mailboxId)
        }`,
        MailByReplyToMailId: gql`
        query mailByReplyToMailId($replyToMailId: ID) {
            mailByReplyToMailId(replyToMailId: $replyToMailId) {
                Id
                Guid
                MailboxId
                MailTypeId
                MailboxFolderId
                HostId
                MailFrom
                MailSender
                MailTo
                MailCc
                MailBcc
                ReplyTo
                ReturnPath
                SentAt
                ReceivedAt
                ContentType
                Importance
                Priority
                MessageId
                InReplyToMessageIds
                ReferenceMessageIds
                Header
                Subject
                BodyHtmlText
                BodyPlainText
                Note
                ReplyToMailId
                ForwardOfMailId
                SeenAt
                SeenBy
                LastSeenAt
                LastSeenBy
                DeletedAt
                DeletedBy
                LastSendAttemptAt
                LastSendAttemptErrorMessage
                OldMailId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                OrderId
                IsReadNotificationRequested
            }
        }`,
        MailByForwardOfMailId: gql`
        query mailByForwardOfMailId($forwardOfMailId: ID) {
            mailByForwardOfMailId(forwardOfMailId: $forwardOfMailId) {
                Id
                Guid
                MailboxId
                MailTypeId
                MailboxFolderId
                HostId
                MailFrom
                MailSender
                MailTo
                MailCc
                MailBcc
                ReplyTo
                ReturnPath
                SentAt
                ReceivedAt
                ContentType
                Importance
                Priority
                MessageId
                InReplyToMessageIds
                ReferenceMessageIds
                Header
                Subject
                BodyHtmlText
                BodyPlainText
                Note
                ReplyToMailId
                ForwardOfMailId
                SeenAt
                SeenBy
                LastSeenAt
                LastSeenBy
                DeletedAt
                DeletedBy
                LastSendAttemptAt
                LastSendAttemptErrorMessage
                OldMailId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                OrderId
                IsReadNotificationRequested
            }
        }`,
        ReferencedMails: gql`
        query referencedMails($mailId: ID) {
            referencedMails(mailId: $mailId) {
                Id
                Guid
                MailboxId
                MailTypeId
                MailboxFolderId
                HostId
                MailFrom
                MailSender
                MailTo
                MailCc
                MailBcc
                ReplyTo
                ReturnPath
                SentAt
                ReceivedAt
                ContentType
                Importance
                Priority
                MessageId
                InReplyToMessageIds
                ReferenceMessageIds
                Header
                Subject
                BodyHtmlText
                BodyPlainText
                Note
                ReplyToMailId
                ForwardOfMailId
                SeenAt
                SeenBy
                LastSeenAt
                LastSeenBy
                DeletedAt
                DeletedBy
                LastSendAttemptAt
                LastSendAttemptErrorMessage
                OldMailId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                OrderId
                IsReadNotificationRequested
            }
        }`,
    },
    Mutations: {
        SendMail: gql`
        mutation sendMail($mail: MailsInput, $attachments: [FilesInput]) {
            sendMail(mail: $mail, attachments: $attachments) {
                IsSuccess
                Message
                StringData
            }
        }`,
        SaveMail: gql`
        mutation saveMail($mail: MailsInput) {
            saveMail(mail: $mail) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
