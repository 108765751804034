// @ts-ignore
import {Products} from "../../../graphql/products/Products.ts";

export class ProductsComponent {
    async saveProduct(apollo, product, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!product) {
                return {
                    IsSuccess: false,
                    Message: "Kein Produkt gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const savedProduct = await apollo.mutate({
                mutation: Products.Mutations.SaveProduct,
                fetchPolicy: "no-cache",
                variables: {
                    product: product,
                    userId: userId,
                },
            })
                .then(({data}) => data?.saveProduct)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedProduct) {
                return {
                    IsSuccess: false,
                    Message: "Das Produkt konnte nicht gespeichert werden.",
                };
            }
            return savedProduct;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getProduct(apollo, productId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productId) {
                return undefined;
            }
            const product = await apollo
                .query({
                    query: Products.Queries.Product,
                    fetchPolicy: "no-cache",
                    variables: {
                        id: productId,
                    },
                })
                .then(({ data }) => data?.product)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!product) {
                return undefined;
            }
            return product;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProducts(apollo, filter, hostId, foreignHostId, userId, productContractId, productStatusIds, isVisibleInItemListing, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const products = await apollo
                .query({
                    query: Products.Queries.Products,
                    variables: {
                        filter: filter,
                        hostId: hostId,
                        foreignHostId: foreignHostId,
                        userId: userId,
                        productContractId: productContractId,
                        productStatusIds: productStatusIds,
                        isVisibleInItemListing: isVisibleInItemListing,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({ data }) => data?.products)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!products) {
                return undefined;
            }
            return products;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductsCount(apollo, filter, hostId, foreignHostId, userId, productContractId, productStatusIds, isVisibleInItemListing) {
        try {
            if (!apollo) {
                return undefined;
            }
            const productsCount = await apollo
                .query({
                    query: Products.Queries.ProductsCount,
                    variables: {
                        filter: filter,
                        hostId: hostId,
                        foreignHostId: foreignHostId,
                        userId: userId,
                        productContractId: productContractId,
                        productStatusIds: productStatusIds,
                        isVisibleInItemListing: isVisibleInItemListing,
                    },
                })
                .then(({ data }) => data?.productsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (productsCount == undefined) {
                return undefined;
            }
            return productsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductByProductVariantId(apollo, productVariantId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productVariantId) {
                return undefined;
            }
            const product = await apollo
                .query({
                    query: Products.Queries.ProductByProductVariantId,
                    variables: {
                        productVariantId: productVariantId,
                    },
                })
                .then(({ data }) => data?.productByProductVariantId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!product) {
                return undefined;
            }
            return product;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductByProductDraftId(apollo, productDraftId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productDraftId) {
                return undefined;
            }
            const product = await apollo
                .query({
                    query: Products.Queries.ProductByProductDraftId,
                    fetchPolicy: "no-cache",
                    variables: {
                        productDraftId: productDraftId,
                    },
                })
                .then(({ data }) => data?.productByProductDraftId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!product) {
                return undefined;
            }
            return product;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductByMakairaItemId(apollo, makairaItemId) {
        try {
            if (!apollo) {
                return undefined;
            }
            const product = await apollo.query({
                query: Products.Queries.ProductByMakairaItemId,
                fetchPolicy: "no-cache",
                variables: {
                    makairaItemId: makairaItemId,
                },
            })
                .then(({data}) => data?.productByMakairaItemId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!product) {
                return undefined;
            }
            return product
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductsByIsHighlightOfTheWeekOnStartPage(apollo, isHighlightOfTheWeekOnStartPage, liveShoppingStart, liveShoppingEnde) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (isHighlightOfTheWeekOnStartPage == undefined) {
                return undefined;
            }
            const products = await apollo.query({
                query: Products.Queries.ProductsByIsHighlightOfTheWeekOnStartPage,
                fetchPolicy: "no-cache",
                variables: {
                    isHighlightOfTheWeekOnStartPage: isHighlightOfTheWeekOnStartPage,
                    liveShoppingStart: liveShoppingStart,
                    liveShoppingEnde: liveShoppingEnde,
                },
            })
                .then(({data}) => data?.productsByIsHighlightOfTheWeekOnStartPage)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!products) {
                return undefined;
            }
            return products;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
