import gql from "graphql-tag";

export const Referrers = {
    Queries: {
        Referrers: gql`
        query referrers {
            referrers {
                Id
                Guid
                Name
                ReferrerId
            }
        }`,
        Referrer: gql`
        query referrer($referrerId: Float) {
            referrer(referrerId: $referrerId) {
                Id
                Guid
                Name
                ReferrerId
            }
        }`,
    },
};
