// @ts-ignore
import {Hosts} from "../../../graphql/hosts/Hosts.ts";

export class HostsComponent {
    async saveHost(apollo, host, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!host) {
                return {
                    IsSuccess: false,
                    Message: "Kein Gastgeber gefunden.",
                };
            }
            if (host.EMAIL1) {
                host.EMAIL1 = host.EMAIL1?.trim()?.toLowerCase();
            }
            if (host.EMAIL2) {
                host.EMAIL2 = host.EMAIL2?.trim()?.toLowerCase();
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            const savedHost = await apollo.mutate({
                mutation: Hosts.Mutations.SaveHost,
                fetchPolicy: "no-cache",
                variables: {
                    host: host,
                    userId: userId,
                },
            })
                .then(({data}) => data?.saveHost)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedHost) {
                return {
                    IsSuccess: false,
                    Message: "Der Gastgeber konnte nicht gespeichert werden.",
                };
            }
            return savedHost;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getHosts(apollo, hostPoolId, filter, activeProducts, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.Hosts,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostPoolId: hostPoolId,
                        filter: filter,
                        hasToHaveActiveProducts: activeProducts,
                        offset: offset,
                        limit: limit,
                    },
                })
                .then(({data}) => data?.hosts)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsCount(apollo, hostPoolId, filter, activeProducts) {
        try {
            if (!apollo) {
                return undefined;
            }
            const hosts = await apollo
                .query({
                    query: Hosts.Queries.HostsCount,
                    fetchPolicy: "no-cache",
                    variables: {
                        hostPoolId: hostPoolId,
                        filter: filter,
                        hasToHaveActiveProducts: activeProducts,
                    },
                })
                .then(({data}) => data?.hostsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHost(apollo, hostId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!hostId) {
                return undefined;
            }
            const host = await apollo
                .query({
                    query: Hosts.Queries.Host,
                    variables: {
                        id: hostId,
                    },
                })
                .then(({data}) => data?.host)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!host) {
                return undefined;
            }
            return host;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getHostsByProductStatusIds(apollo, productStatusIds, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productStatusIds) {
                return undefined;
            }
            const hosts = await apollo.query({
                query: Hosts.Queries.HostsByProductStatusIds,
                fetchPolicy: "no-cache",
                variables: {
                    productStatusIds: productStatusIds,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.hostsByProductStatusIds)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!hosts) {
                return undefined;
            }
            return hosts;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
