import gql from "graphql-tag";

export const ProductStatus = {
    Queries: {
        ProductStatus: gql`
        query productStatus($isActive: Boolean, $offset: Int, $limit: Int) {
            productStatus(isActive: $isActive, offset: $offset, limit: $limit) {
                Id
                Name
                Description
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsActive
            }
        }`,
        ProductStatusCount: gql`
        query productStatusCount($isActive: Boolean) {
            productStatusCount(isActive: $isActive)
        }`,
        ProductStatusById: gql`
        query productStatusById($id: ID) {
            productStatusById(id: $id) {
                Id
                Name
                Description
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsActive
            }
        }`,
    },
    Mutations: {
        SaveProductStatus: gql`
        mutation saveProductStatus($productStatus: ProductStatusInput) {
            saveProductStatus(productStatus: $productStatus) {
                IsSuccess
                Message
            }    
        }`,
        UpdateProductStatus: gql`
        mutation updateProductStatus($productId: ID, $productStatusId: ID, $userId: ID, $note: String) {
            updateProductStatus(productId: $productId, productStatusId: $productStatusId, userId: $userId, note: $note) {
                IsSuccess
                Message
            }
        }`,
        CheckProductStatus: gql`
        mutation checkProductStatus($productId: ID) {
            checkProductStatus(productId: $productId) {
                IsSuccess
                Message
            }
        }`,
    }
};
