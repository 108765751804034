import gql from "graphql-tag";

export const ShippingTypes = {
    Queries: {
        ShippingType: gql`
        query shippingType($id: ID) {
            shippingType(id: $id) {
                Id
                Name
            }
        }`,
        ShippingTypes: gql`
        query shippingTypes {
            shippingTypes {
                Id
                Name
            }
        }`,
    }
};
