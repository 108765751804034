<template lang="pug">
  .row
    .col-4
      span Id: {{ voucher.Id }}
      br
      span Status:&nbsp;
      span(v-if="voucherStatus") {{ voucherStatus.Description }}
      span(v-else) lädt...
    .col-8
      template(v-if="order")
        span Auftrag: {{ order.Id }}
        br
        span Bestellnummer: {{ order.OrderId }}
        br
        span Plattform: {{ getPlatform() }}
        br
        span Bestelldatum: {{ order.OrderDate | formatDate }}
        br
        span Zahlart: {{ getPaymentType() }}
        br
        span Gesamtkosten: {{ order.TotalInvoiceGross | formatEuro }}
        br
        span Bezahlt am: {{ order.PaidAt | formatDateTime }}
        br
        span Käufer:&nbsp;
        span(v-if="address") {{ address.FirstName }} {{ address.LastName }} [{{ address.City }}]
        span(v-else) lädt...
        br
        span E-Mail:&nbsp;
        span(v-if="address") {{ address.Email }}
        span(v-else) lädt...
      span(v-else) lädt...

</template>

<script>
import { AddressesComponent } from "@/lib/components/addresses/AddressesComponent.ts";
import { PlatformsEnum } from "@/utils/enums/platforms/PlatformsEnum.ts";
import { PaymentTypesEnum } from "@/utils/enums/paymentTypes/PaymentTypesEnum.ts";
import { OrdersComponent } from "@/lib/components/orders/OrdersComponent.ts";
import { VoucherStatusComponent } from "@/lib/components/voucherStatus/VoucherStatusComponent.ts";

export default {
  name: "VoucherSearchSelectListResultsListItem",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      voucherStatus: undefined,
      order: undefined,
      address: undefined,
    };
  },
  async mounted() {
    try {
      this.loadVoucherStatus();
      this.loadOrder();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVoucherStatus() {
      try {
        const voucherStatusComponent = new VoucherStatusComponent();
        const voucherStatus = await voucherStatusComponent.getVoucherStatusById(
          this.$apollo,
          this.voucher.Status
        );
        if (!voucherStatus) {
          return;
        }
        this.voucherStatus = voucherStatus;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadOrder() {
      try {
        const ordersComponent = new OrdersComponent();
        const order = await ordersComponent.getOrder(
          this.$apollo,
          this.voucher.OrderId
        );
        if (!order) {
          return;
        }
        this.order = order;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddress() {
      try {
        const addressesComponent = new AddressesComponent();
        const address = await addressesComponent.getAddress(
          this.$apollo,
          this.order.BillingAddressId
        );
        if (!address) {
          return;
        }
        this.address = address;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    getPlatform() {
      try {
        if (!this.order) {
          return undefined;
        }
        if (this.order.PlatformId == PlatformsEnum.Afterbuy) {
          return "eBay";
        } else if (this.order.PlatformId == PlatformsEnum.Plentymarkets) {
          return "Plentymarkets";
        } else if (this.order.PlatformId == PlatformsEnum.Makaira) {
          return "Moloss";
        } else if (this.order.PlatformId == PlatformsEnum.Internal) {
          return "Intern";
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    getPaymentType() {
      try {
        if (!this.order) {
          return undefined;
        }
        if (this.order.PaymentTypeId == PaymentTypesEnum.Transfer) {
          return "Überweisung";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.Paypal) {
          return "PayPal";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.Klarna) {
          return "Klarna";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.Ebay) {
          return "eBay";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.AmazonPay) {
          return "AmazonPay";
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  watch: {
    order() {
      try {
        this.loadAddress();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
