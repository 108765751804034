<template lang="pug">
tr(:class="{ 'd-none': !isVisible }")
  td(colspan=10)
    b-collapse(:id="moment(date).format('DDMMYYYY')" v-model="isVisible")
      .table-responsive(v-if="isVisible")
        table.table.table-bordered
          thead
            tr
              th Herkunft
              th Umsatz
              th touriDays
              th Post
              th Geschenkbox
              th Storno-Quote
              th Sales
              th Ø-Warenkorb
          tbody
            evaluations-table-sub-row(
              v-for="valuableReferrer in valuableReferrers"
              :referrer-id="valuableReferrer.ReferrerId"
              :from="from"
              :to="to"
              :display-unit="displayUnit"
              :platform-ids="platformIds"
              :transaction-type-ids="transactionTypeIds"
              :shipping-type-ids="shippingTypeIds"
              :order-status-codes="orderStatusCodes"
            )

</template>

<script>
import EvaluationsTableHeadRow from "@/views/evaluations/partials/EvaluationsTableHeadRow.vue";
import { Orders } from "@/graphql/orders/Orders.ts";
import EvaluationsTableSubRow from "@/views/evaluations/partials/EvaluationsTableSubRow.vue";
import moment from "moment";

export default {
  name: "EvaluationsTableSubTable",
  components: { EvaluationsTableSubRow, EvaluationsTableHeadRow },
  props: {
    date: {
      type: Date,
      required: true,
    },
    displayUnit: {
      type: String,
      required: true,
    },
    platformIds: {
      type: Array,
      required: false,
    },
    referrerIds: {
      type: Array,
      required: false,
    },
    transactionTypeIds: {
      type: Array,
      required: false,
    },
    shippingTypeIds: {
      type: Array,
      required: false,
    },
    orderStatusCodes: {
      type: Array,
      required: false,
    },
  },
  async mounted() {
    try {
      this.loadOrders();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  data() {
    return {
      orders: [],

      from: moment(this.date).toDate(),
      to: moment(this.date).endOf(this.displayUnit).toDate(),

      isVisible: false,
      moment: moment,
    };
  },
  methods: {
    async loadOrders() {
      try {
        const orders = await this.$apollo
          .query({
            query: Orders.Queries.Orders,
            variables: {
              from: this.from,
              to: this.to,
              platformIds: this.platformIds,
              referrerIds: this.referrerIds,
              transactionTypeIds: this.transactionTypeIds,
              shippingTypeIds: this.shippingTypeIds,
              orderStatusCodes: this.orderStatusCodes,
            },
          })
          .then(({ data }) => data?.orders)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!orders) {
          return;
        }
        this.orders = orders;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    valuableReferrers() {
      try {
        if (!this.orders) {
          return undefined;
        }
        if (this.orders.length == 0) {
          return undefined;
        }
        let valuableReferrers = [];
        for (const order of this.orders) {
          if (valuableReferrers.some((r) => r.ReferrerId == order.ReferrerId)) {
            const valuableReferrer = valuableReferrers.find(
              (r) => r.ReferrerId == order.ReferrerId
            );
            if (!valuableReferrer) {
              continue;
            }
            valuableReferrer.TotalSalesValue += order.TotalInvoiceGross;
            continue;
          }
          valuableReferrers.push({
            ReferrerId: order.ReferrerId,
            TotalSalesValue: order.TotalInvoiceGross,
          });
        }
        valuableReferrers = valuableReferrers.sort(
          (a, b) => b.TotalSalesValue - a.TotalSalesValue
        );
        return valuableReferrers;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
