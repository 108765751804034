// @ts-ignore
import {Newsletters} from "../../../graphql/newsletters/Newsletters.ts";

export class NewslettersComponent {
  async getNewsletters(apollo, newsletterStatusIds, filter, offset, limit) {
    try {
      if (!apollo) {
        return undefined;
      }
      const newsletters = await apollo
        .query({
          query: Newsletters.Queries.Newsletters,
          variables: {
            newsletterStatusIds: newsletterStatusIds,
            filter: filter,
            offset: offset,
            limit: limit,
          },
        })
        .then(({ data }) => data?.newsletters)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!newsletters) {
        return undefined;
      }
      return newsletters;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getNewslettersCount(apollo, newsletterStatusIds, filter) {
    try {
      if (!apollo) {
        return undefined;
      }
      const newslettersCount = await apollo
          .query({
            query: Newsletters.Queries.NewslettersCount,
            variables: {
              newsletterStatusIds: newsletterStatusIds,
              filter: filter,
            },
          })
          .then(({ data }) => data?.newslettersCount)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (newslettersCount == undefined) {
        return undefined;
      }
      return newslettersCount;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async checkNewsletter(apollo, newsletterId) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!newsletterId) {
        return {
          IsSuccess: false,
          Message: "Keine Newsletter-Id gefunden.",
        };
      }
      const checkedNewsletter = await apollo.mutate({
        mutation: Newsletters.Mutations.CheckNewsletter,
        fetchPolicy: "no-cache",
        variables: {
          newsletterId: newsletterId,
        },
      })
          .then(({data}) => data?.checkNewsletter)
          .catch((e) => {
            console.error(e);
            return {
              IsSuccess: false,
              Message: e.message,
            };
          });
      return checkedNewsletter;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}
