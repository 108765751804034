// @ts-ignore
import {ProductStatus} from "../../../graphql/productStatus/ProductStatus.ts";

export class ProductStatusComponent {
    async getProductStatus(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const productStatus = await apollo.query({
                query: ProductStatus.Queries.ProductStatus,
            })
                .then(({data}) => data?.productStatus)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!productStatus) {
                return undefined;
            }
            return productStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductStatusById(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const productStatusById = await apollo.query({
                query: ProductStatus.Queries.ProductStatusById,
                variables: {
                    id: id,
                }
            })
                .then(({data}) => data?.productStatusById)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!productStatusById) {
                return undefined;
            }
            return productStatusById;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
