// @ts-ignore
import {ServiceTypes} from "../../../graphql/serviceTypes/ServiceTypes.ts"

export class ServiceTypesComponent {
    async getServiceTypes(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const serviceTypes = await apollo.query({
                query: ServiceTypes.Queries.ServiceTypes,
            })
                .then(({data}) => data?.serviceTypes)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!serviceTypes) {
                return undefined;
            }
            return serviceTypes;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getServiceType(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const serviceType = await apollo.query({
                query: ServiceTypes.Queries.ServiceType,
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.serviceType)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!serviceType) {
                return undefined;
            }
            return serviceType;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
