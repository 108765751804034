import gql from "graphql-tag";

export const TransactionTypes = {
    Queries: {
        TransactionType: gql`
        query transactionType($id: ID) {
            transactionType(id: $id) {
                Id
                Guid
                Name
            }
        }`,
        TransactionTypes: gql`
        query transactionTypes {
            transactionTypes {
                Id
                Guid
                Name
            }
        }`,
    },
};
