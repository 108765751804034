// @ts-ignore
import {ProductContractStatus} from "../../../graphql/productContractStatus/ProductContractStatus.ts";

export class ProductContractStatusComponent {
    async getProductContractStatusById(apollo, id) {
        try {
            if (!apollo || !id) {
                return undefined;
            }
            const productContractStatus = await apollo.query({
                query: ProductContractStatus.Queries.ProductContractStatusById,
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.productContractStatusById)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return productContractStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getProductContractStatus(apollo) {
        try {
            if (!apollo) {
                return undefined;
            }
            const productContractStatus = await apollo.query({
                query: ProductContractStatus.Queries.ProductContractStatus,
            })
                .then(({data}) => data?.productContractStatus)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            return productContractStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
