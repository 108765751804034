// @ts-ignore
import {Callbacks} from "../../../graphql/callbacks/Callbacks.ts";

export class CallbacksComponent {
    async getCallbacks(apollo, isDone, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const callbacks = await apollo.query({
                query: Callbacks.Queries.Callbacks,
                fetchPolicy: "no-cache",
                variables: {
                    isDone: isDone,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.callbacks)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!callbacks) {
                return undefined;
            }
            return callbacks;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getCallbacksCount(apollo, isDone) {
        try {
            if (!apollo) {
                return undefined;
            }
            const callbacksCount = await apollo.query({
                query: Callbacks.Queries.CallbacksCount,
                fetchPolicy: "no-cache",
                variables: {
                    isDone: isDone,
                },
            })
                .then(({data}) => data?.callbacksCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!callbacksCount) {
                return undefined;
            }
            return callbacksCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getCallback(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const callback = await apollo.query({
                query: Callbacks.Queries.Callback,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.callback)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!callback) {
                return undefined;
            }
            return callback;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveCallback(apollo, callback) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!callback) {
                return {
                    IsSuccess: false,
                    Message: "Kein Rückruf gefunden.",
                };
            }
            const savedCallback = await apollo.mutate({
                mutation: Callbacks.Mutations.SaveCallback,
                fetchPolicy: "no-cache",
                variables: {
                    callback: callback,
                },
            })
                .then(({data}) => data?.saveCallback)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!savedCallback) {
                return {
                    IsSuccess: false,
                    Message: "Der Rückruf konnte nicht gespeichert werden.",
                };
            }
            return savedCallback;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
