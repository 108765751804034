<template lang="pug">
.table-responsive
  table.table.table-bordered
    thead
      tr
        th(v-if="notificationsToDelete && Array.isArray(notificationsToDelete) && selectAllNotifications && setNotificationsAsDone")
          .d-flex
            input.mr-2(type="checkbox" @change="selectAllNotifications" :checked="notificationsToDelete.length > 0 && notificationsToDelete.length == notifications.length")
            button.button.button-primary.button-tbook(@click="setNotificationsAsDone") erledigen
        th Gastgeber/Produkt
        th Datum
        th Titel
        th Benutzer
        th erstellt am
        th erstellt von
        th erledigt am
        th
    tbody
      notifications-table-row(
        v-for="notification in notifications"
        :notification="notification"
        :notifications-to-delete="notificationsToDelete"
        :handle-deletion="handleDeletion"
        :open-notification="openNotification"
      )

</template>

<script>
import Pagination from "@/views/partials/pagination.vue";
import NotificationsTableRow from "@/views/notifications/NotificationsTableRow.vue";
export default {
  name: "NotificationsTable",
  components: { NotificationsTableRow, Pagination },
  props: {
    notifications: {
      type: Array,
      required: true,
    },
    notificationsToDelete: {
      type: Array,
      required: false,
    },
    selectAllNotifications: {
      type: Function,
      required: false,
    },
    setNotificationsAsDone: {
      type: Function,
      required: false,
    },
    handleDeletion: {
      type: Function,
      required: false,
    },
    openNotification: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped></style>
