import gql from "graphql-tag";

export const NewsletterProducts = {
    Queries: {
        NewsletterProducts: gql`
        query newsletterProducts($newsletterId: ID) {
            newsletterProducts(newsletterId: $newsletterId) {
                Id
                ProductId
                CreatedAt
                CreatedBy
                Position
                Headline1
                Headline2
                Subline1
                Subline2
                Extraline
                UtilityLine
                ValidationLine
                UpdatedAt
                UpdatedBy
                NewsletterId
                ImageId
                Color
            }
        }`,
    },
    Mutations: {
        CreateNewsletterProduct: gql`
        mutation createNewsletterProduct($productId: ID, $newsletterId: ID, $userId: ID) {
            createNewsletterProduct(productId: $productId, newsletterId: $newsletterId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        DeleteNewsletterProduct: gql`
        mutation deleteNewsletterProduct($newsletterProductId: ID) {
            deleteNewsletterProduct(newsletterProductId: $newsletterProductId) {
                IsSuccess
                Message
            }
        }`,
        UpdateNewsletterProducts: gql`
        mutation updateNewsletterProducts($newsletterProducts: [NewsletterProductsInput], $userId: ID) {
            updateNewsletterProducts(newsletterProducts: $newsletterProducts, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
    },
};
