// @ts-ignore
import {Items} from "../../../graphql/items/Items.ts";

export class ItemsComponent {
  async getItems(apollo, productId, hostId, orderId, newsletterId, from, to, platformIds, referrerIds, transactionTypeIds, shippingTypeIds, orderStatusCodes, advertisingTypeIds) {
    try {
      if (!apollo) {
        return undefined;
      }
      const items = await apollo.query({
        query: Items.Queries.Items,
        variables: {
          productId: productId,
          hostId: hostId,
          orderId: orderId,
          newsletterId: newsletterId,
          from: from,
          to: to,
          platformIds: platformIds,
          referrerIds: referrerIds,
          transactionTypeIds: transactionTypeIds,
          shippingTypeIds: shippingTypeIds,
          orderStatusCodes: orderStatusCodes,
          advertisingTypeIds: advertisingTypeIds,
        },
      })
          .then(({data}) => data?.items)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!items) {
        return undefined;
      }
      return items;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getItemByVoucherId(apollo, voucherId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!voucherId) {
        return undefined;
      }
      const item = await apollo
        .query({
          query: Items.Queries.ItemByVoucherId,
          variables: {
            voucherId: voucherId,
          },
        })
        .then(({ data }) => data?.itemByVoucherId)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!item) {
        return undefined;
      }
      return item;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
