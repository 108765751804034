<template lang="pug">
  .container-fluid.p-5
    .row
      .col-12
        .section-headline Rückrufe
      .col-12.mt-3
        .table-responsive
          table.table.table-bordered
            thead
              tr
                th Für
                th Name
                th Kontaktadresse
                th Bemerkung
                th Rückruf von/bis
                th Gastgeber
                th Gutschein
                th Erstellt von
                th Erstellt am
                th Erledigt von
                th Erledigt am
                th #
            tbody
              tr(v-for="callback in callbacks")
                td {{ callback.Id }}
                td {{ callback.Name }}
                td {{ callback.Phone }}
                td {{ callback.Reason }}
                td {{ callback.CallbackTo }}
                td {{ callback.HostId }}
                td {{ callback.VoucherId }}
                td {{ callback.CreatedBy }}
                td {{ callback.CreatedAt | formatDateTime }}
                td {{ callback.DoneBy }}
                td {{ callback.DoneAt }}
                td
                  button.button.button-primary.button-tdays(@click="openCallbackModal(callback.Id)") öffnen
      .col-12.mt-3
        Pagination(:load="loadCallbacks" :overall-count="callbacksCount" :offset="offset" :limit="limit")
      .col-12.mt-3
        .float-right
          button.button.button-primary.button-tdays(@click="openCallbackModal(undefined)") neuer Rückruf

</template>

<script>
import EventBus from "@/event-bus";
import Pagination from "@/views/partials/pagination.vue";
import CallbackModal from "@/views/components/callbacks/CallbackModal.vue";
import { CallbacksComponent } from "@/lib/components/callbacks/CallbacksComponent.ts";

export default {
  name: "CallbacksModal",
  components: { Pagination },
  data() {
    return {
      callbacks: [],
      callbacksCount: 0,

      offset: 0,
      limit: 15,
    };
  },
  async mounted() {
    try {
      if (this.$router.currentRoute.query.offset) {
        this.offset = parseInt(this.$router.currentRoute.query.offset);
      }
      EventBus.$on(
        "updateCallbacks",
        function () {
          this.callbacks = [];
          this.offset = 0;
          this.loadCallbacks();
          this.loadCallbacksCount();
        }.bind(this)
      );
      this.loadCallbacks();
      this.loadCallbacksCount();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadCallbacks(offset) {
      try {
        if (offset != undefined) {
          this.offset = offset;
        }
        const callbacksComponent = new CallbacksComponent();
        EventBus.$emit("changeLoadingState", true);
        const callbacks = await callbacksComponent.getCallbacks(
          this.$apollo,
          false
        );
        EventBus.$emit("changeLoadingState", false);
        if (!callbacks) {
          return this.$alert("Es konnten keine Rückrufe geladen werden.");
        }
        this.callbacks = callbacks;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadCallbacksCount() {
      try {
        const callbacksComponent = new CallbacksComponent();
        const callbacksCount = await callbacksComponent.getCallbacksCount(
          this.$apollo,
          false
        );
        if (callbacksCount == undefined) {
          return;
        }
        this.callbacksCount = callbacksCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    openCallbackModal(id) {
      try {
        this.$modal.show(
          CallbackModal,
          {
            callbackId: id,
          },
          {
            classes: ["rounded-0", "w-auto"],
          }
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
