<template lang="pug">
  .user-search-select-list-results-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'user']")
    span {{ user.FirstName }} {{ user.LastName }} <{{ user.Email }}>

</template>

<script>
export default {
  name: "UserSearchSelectListResultsListItem",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
