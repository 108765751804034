// @ts-ignore
import {UserGroups} from "../../../graphql/userGroups/UserGroups.ts";

export class UserGroupsComponent {
    async getUserGroups(apollo, filter, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const userGroups = await apollo.query({
                query: UserGroups.Queries.UserGroups,
                fetchPolicy: "no-cache",
                variables: {
                    filter: filter,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.userGroups)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!userGroups) {
                return undefined;
            }
            return userGroups;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getUserGroup(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const userGroup = await apollo.query({
                query: UserGroups.Queries.UserGroup,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.userGroup)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!userGroup) {
                return undefined;
            }
            return userGroup;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
