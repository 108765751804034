import gql from "graphql-tag";

export const Orders = {
    Queries: {
        Orders: gql`
        query orders($filter: String, $from: Date, $to: Date, $paymentStatusCodes: [ID], $orderStatusCodes: [ID], $platformIds: [ID], $referrerIds: [ID], $transactionTypeIds: [ID], $shippingTypeIds: [ID], $offset: Int, $limit: Int) {
            orders(filter: $filter, from: $from, to: $to, paymentStatusCodes: $paymentStatusCodes, orderStatusCodes: $orderStatusCodes, platformIds: $platformIds, referrerIds: $referrerIds, transactionTypeIds: $transactionTypeIds, shippingTypeIds: $shippingTypeIds, offset: $offset, limit: $limit) {
                Id
                Guid
                CreatedAt
                QueryId
                OrderId
                OrderNumber
                PlatformId
                ShopId
                OrderNumber
                OrderDate
                ShippingTypeId
                TotalShippingCostsGross
                IncludedShippingCosts
                TotalInvoiceGross
                TotalInvoiceVAT
                PaymentStatusId
                PaymentTypeId
                PaidAt
                PaidAtDate
                BillingAddressId
                DeliveryAddressId
                SourceXml
                UpdatedAt
                Cancelled
                CancelDate
                CancelledBy
                ConfirmationMailSentDate
                VoucherMailSentDate
                Country
                OrderStatusId
                CustomerID
                CancelMailSentDate
                Invoiced
                AlreadyPaid
                VoucherPostSentDate
                VoucherPostShipmentMailSentDate
                Note
                PaymentNotificationMailSentDate
                CheckedForVirtualInvoice
                GoodsIssueBookedOn
                GoodsIssueBookedBy
                KlarnaReservationNumber
                KlarnaInvoiceNumber
                ReferrerId
                KlarnaOrderId
                FirstDunningRunId
                FirstReminderLastSentOn
                SecondDunningRunId
                SecondReminderLastSentOn
                ThirdPartyOrderId
                CreatedBy
                UpdatedBy
            }
        }`,
        OrdersCount: gql`
        query ordersCount($filter: String, $from: Date, $to: Date, $paymentStatusCodes: [ID], $orderStatusCodes: [ID], $platformIds: [ID], $referrerIds: [ID], $transactionTypeIds: [ID], $shippingTypeIds: [ID]) {
            ordersCount(filter: $filter, from: $from, to: $to, paymentStatusCodes: $paymentStatusCodes, orderStatusCodes: $orderStatusCodes, platformIds: $platformIds, referrerIds: $referrerIds, transactionTypeIds: $transactionTypeIds, shippingTypeIds: $shippingTypeIds)
        }`,
        Order: gql`
        query order($id: ID) {
            order(id: $id) {
                Id
                Guid
                CreatedAt
                QueryId
                OrderId
                PlatformId
                ShopId
                OrderNumber
                OrderDate
                ShippingTypeId
                TotalShippingCostsGross
                IncludedShippingCosts
                TotalInvoiceGross
                TotalInvoiceVAT
                PaymentStatusId
                PaymentTypeId
                PaidAt
                PaidAtDate
                BillingAddressId
                DeliveryAddressId
                SourceXml
                UpdatedAt
                Cancelled
                CancelDate
                CancelledBy
                ConfirmationMailSentDate
                VoucherMailSentDate
                Country
                OrderStatusId
                CustomerID
                CancelMailSentDate
                Invoiced
                AlreadyPaid
                VoucherPostSentDate
                VoucherPostShipmentMailSentDate
                Note
                PaymentNotificationMailSentDate
                CheckedForVirtualInvoice
                GoodsIssueBookedOn
                GoodsIssueBookedBy
                KlarnaReservationNumber
                KlarnaInvoiceNumber
                ReferrerId
                KlarnaOrderId
                FirstDunningRunId
                FirstReminderLastSentOn
                SecondDunningRunId
                SecondReminderLastSentOn
                ThirdPartyOrderId
                CreatedBy
                UpdatedBy
            }
        }`,
        OrderByOrderNumber: gql`
        query orderByOrderNumber($orderNumber: String) {
            orderByOrderNumber(orderNumber: $orderNumber) {
                Id
                Guid
                CreatedAt
                QueryId
                OrderId
                PlatformId
                ShopId
                OrderNumber
                OrderDate
                ShippingTypeId
                TotalShippingCostsGross
                IncludedShippingCosts
                TotalInvoiceGross
                TotalInvoiceVAT
                PaymentStatusId
                PaymentTypeId
                PaidAt
                PaidAtDate
                BillingAddressId
                DeliveryAddressId
                SourceXml
                UpdatedAt
                Cancelled
                CancelDate
                CancelledBy
                ConfirmationMailSentDate
                VoucherMailSentDate
                Country
                OrderStatusId
                CustomerID
                CancelMailSentDate
                Invoiced
                AlreadyPaid
                VoucherPostSentDate
                VoucherPostShipmentMailSentDate
                Note
                PaymentNotificationMailSentDate
                CheckedForVirtualInvoice
                GoodsIssueBookedOn
                GoodsIssueBookedBy
                KlarnaReservationNumber
                KlarnaInvoiceNumber
                ReferrerId
                KlarnaOrderId
                FirstDunningRunId
                FirstReminderLastSentOn
                SecondDunningRunId
                SecondReminderLastSentOn
                ThirdPartyOrderId
                CreatedBy
                UpdatedBy
            }
        }`,
        OrderByGuid: gql`
        query orderByGuid($guid: String) {
            orderByGuid(guid: $guid) {
                Id
                Guid
                CreatedAt
                QueryId
                OrderId
                PlatformId
                ShopId
                OrderNumber
                OrderDate
                ShippingTypeId
                TotalShippingCostsGross
                IncludedShippingCosts
                TotalInvoiceGross
                TotalInvoiceVAT
                PaymentStatusId
                PaymentTypeId
                PaidAt
                PaidAtDate
                BillingAddressId
                DeliveryAddressId
                SourceXml
                UpdatedAt
                Cancelled
                CancelDate
                CancelledBy
                ConfirmationMailSentDate
                VoucherMailSentDate
                Country
                OrderStatusId
                CustomerID
                CancelMailSentDate
                Invoiced
                AlreadyPaid
                VoucherPostSentDate
                VoucherPostShipmentMailSentDate
                Note
                PaymentNotificationMailSentDate
                CheckedForVirtualInvoice
                GoodsIssueBookedOn
                GoodsIssueBookedBy
                KlarnaReservationNumber
                KlarnaInvoiceNumber
                ReferrerId
                KlarnaOrderId
                FirstDunningRunId
                FirstReminderLastSentOn
                SecondDunningRunId
                SecondReminderLastSentOn
                ThirdPartyOrderId
                CreatedBy
                UpdatedBy
            }
        }`,
    },
    Mutations: {
        SaveOrder: gql`
        mutation saveOrder($order: OrdersInput, $items: [ItemsInput], $shopVoucher: ShopVouchersInput, $userId: ID) {
            saveOrder(order: $order, items: $items, shopVoucher: $shopVoucher, userId: $userId) {
                IsSuccess
                Message
                StringData
            }
        }`,
        UpdateOrder: gql`
        mutation updateOrder($order: OrdersInput) {
            updateOrder(order: $order) {
                IsSuccess
                Message
            }
        }`,
        SyncOrder: gql`
        mutation syncOrder($orderId: ID, $userId: ID) {
            syncOrder(orderId: $orderId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        BookGoodsIssue: gql`
        mutation bookGoodsIssue($orderId: ID, $userId: ID) {
            bookGoodsIssue(orderId: $orderId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        CancelOrder: gql`
        mutation cancelOrder($orderId: ID, $userId: ID) {
            cancelOrder(orderId: $orderId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        RefundOrder: gql`
        mutation refundOrder($orderId: ID, $userId: ID, $refundAmount: Float) {
            refundOrder(orderId: $orderId, userId: $userId, refundAmount: $refundAmount) {
                IsSuccess
                Message
            }
        }`,
        SendOrderConfirmationEmail: gql`
        mutation sendOrderConfirmationEmail($orderId: ID, $userId: ID, $email: String) {
            sendOrderConfirmationEmail(orderId: $orderId, userId: $userId, email: $email) {
                IsSuccess
                Message
            }
        }`,
        SendOrderVouchersEmail: gql`
        mutation sendOrderVouchersEmail($orderId: ID, $userId: ID, $email: String) {
            sendOrderVouchersEmail(orderId: $orderId, userId: $userId, email: $email) {
                IsSuccess
                Message
            }
        }`,
        SendOrderCancellationEmail: gql`
        mutation sendOrderCancellationEmail($orderId: ID, $userId: ID, $email: String) {
            sendOrderCancellationEmail(orderId: $orderId, userId: $userId, email: $email) {
                IsSuccess
                Message
            }
        }`,
        SendOrderVouchers: gql`
        mutation sendOrderVouchers($id: ID, $email: String) {
            sendOrderVouchers(id: $id, email: $email) {
                IsSuccess
                Message
            }
        }`,
        PrintOrder: gql`
        mutation printOrder($orderId: ID, $userId: ID) {
            printOrder(orderId: $orderId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        PrintOrderDeliveryNote: gql`
        mutation printOrderDeliveryNote($orderId: ID, $userId: ID) {
            printOrderDeliveryNote(orderId: $orderId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
    }
};
