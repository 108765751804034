import gql from "graphql-tag";

export const OrderStatus = {
    Queries: {
        OrderStatusById: gql`
        query orderStatusById($id: ID) {
            orderStatusById(id: $id) {
                Id
                StatusCode
                Definition
            }
        }`,
        OrderStatus: gql`
        query orderStatus {
            orderStatus {
                Id
                StatusCode
                Definition
            }
        }`,
    },
};
