import gql from "graphql-tag";

export const ServiceTypes = {
    Queries: {
        ServiceTypes: gql`
        query serviceTypes {
            serviceTypes {
                Id
                Description
                Name
            }
        }`,
        ServiceType: gql`
        query serviceType($id: ID) {
            serviceType(id: $id) {
                Id
                Description
                Name
            }
        }`,
    }
};
