import gql from "graphql-tag";

export const UserGroups = {
    Queries: {
        UserGroups: gql`
        query userGroups($offset: Int, $limit: Int, $filter: String) {
            userGroups(offset: $offset, limit: $limit, filter: $filter) {
                Id
                Name
                Description
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                Indicator
            }
        }`,
        UserGroupsCount: gql`
        query userGroupsCount {
            userGroupsCount
        }`,
        UserGroup: gql`
        query userGroup($id: ID) {
            userGroup(id: $id) {
                Id
                Name
                Description
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                Indicator
            }
        }`,
        UserGroupsByUserId: gql`
        query userGroupsByUserId($userId: ID) {
            userGroupsByUserId(userId: $userId) {
                Id
                Name
                Description
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                Indicator
            }
        }`,
    },
    Mutations: {
        SaveUserGroup: gql`
        mutation saveUserGroup($userGroup: UserGroupsInput) {
            saveUserGroup(userGroup: $userGroup) {
                IsSuccess
                Message
            }
        }`,
    }
};
