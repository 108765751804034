import gql from "graphql-tag";

export const ProductContractStatus = {
    Queries: {
        ProductContractStatusById: gql`
        query productContractStatusById($id: ID) {
            productContractStatusById(id: $id) {
                Id
                Guid
                Description
            }
        }`,
        ProductContractStatus: gql`
        query productContractStatus {
            productContractStatus {
                Id
                Guid
                Description
            }
        }`,
    }
}
