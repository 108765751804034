import gql from "graphql-tag";

export const VoucherStatus = {
    Queries: {
        VoucherStatusById: gql`
        query voucherStatusById($id: ID) {
            voucherStatusById(id: $id) {
                Id
                Description
            }
        }`,
    },
};
