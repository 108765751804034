<template lang="pug">
tr(:class="{ 'bg-tpics-lighter': notification.NotificationPriorityId == 2 }")
  td(v-if="notificationsToDelete && Array.isArray(notificationsToDelete)")
    input(type="checkbox" :checked="notificationsToDelete.some((n) => n.Id == notification.Id)" @change="handleDeletion(notification)")
  td
    a(:href="notification.RedirectUrl" target="_blank" v-if="notification.RedirectUrl")
      span(v-if="host") {{ host.Name }}
      span(v-else-if="product") {{ product.Name1 }}
    router-link(:to="{ name: 'HostDetails', params: { hostId: host.Id } }" v-else-if="host") {{ host.Name }}
    router-link(:to="{ name: 'ProductDetails', params: { hostId: product.HostId, productId: product.Id} }" v-else-if="product") {{ product.Name1 }}
  td {{ notification.NotificationDate | formatDateTime }}
  td {{ notification.Title }}
  td
    span(v-if="user") {{ user.FirstName }} {{ user.LastName }}
    span(v-else-if="userGroup") {{ userGroup.Name }}
  td {{ notification.CreatedAt | formatDateTime }}
  td
    span(v-if="createdBy") {{ createdBy.FirstName }} {{ createdBy.LastName }}
  td {{ notification.DoneAt | formatDateTime }}
  td
    .float-right
      a.button.button-primary.button-tdays(@click="openNotification(notification.Id)") öffnen

</template>

<script>
import { Users } from "@/graphql/users/Users.ts";
import { UserGroups } from "@/graphql/userGroups/UserGroups.ts";
import { ProductsComponent } from "@/lib/components/products/ProductsComponent.ts";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent.ts";

export default {
  name: "NotificationsTableRow",
  props: {
    notification: {
      type: Object,
      required: true,
    },
    notificationsToDelete: {
      type: Array,
      required: false,
    },
    handleDeletion: {
      type: Function,
      required: true,
    },
    openNotification: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      host: undefined,
      product: undefined,
      user: undefined,
      createdBy: undefined,
      userGroup: undefined,
      sourceUser: undefined,
      sourceUserGroup: undefined,
    };
  },
  async mounted() {
    try {
      this.user = await this.getUser(this.notification.UserId);
      this.createdBy = await this.getUser(this.notification.CreatedBy);
      this.userGroup = await this.getUserGroup(this.notification.UserGroupId);
      this.sourceUser = await this.getUser(this.notification.SourceUserId);
      this.sourceUserGroup = await this.getUserGroup(
        this.notification.SourceUserGroupId
      );
      this.loadHost();
      this.loadProduct();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async getUser(id) {
      try {
        const user = await this.$apollo
          .query({
            query: Users.Queries.User,
            variables: {
              id: id,
            },
          })
          .then(({ data }) => data?.user)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!user) {
          return undefined;
        }
        return user;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async getUserGroup(id) {
      try {
        const userGroup = await this.$apollo
          .query({
            query: UserGroups.Queries.UserGroup,
            variables: {
              id: id,
            },
          })
          .then(({ data }) => data?.userGroup)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!userGroup) {
          return undefined;
        }
        return userGroup;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    async loadHost() {
      try {
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(
          this.$apollo,
          this.notification.HostId
        );
        if (!host) {
          return;
        }
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.notification.ProductId
        );
        if (!product) {
          return;
        }
        this.product = product;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    "notification.HostId"() {
      try {
        if (!this.notification?.HostId) {
          return;
        }
        this.loadHost();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    "notification.ProductId"() {
      try {
        if (!this.notification?.ProductId) {
          return;
        }
        this.loadProduct();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
