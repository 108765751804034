<template lang="pug">
  tr
    td {{ service.Id }}
    td {{ service.Description }}
    td
      span(v-if="serviceType") {{ serviceType.Name }}
      span(v-else) lädt...
    td {{ service.CreatedAt | formatDate }}
    td {{ service.UpdatedAt | formatDate }}
    td(v-if="service.ServiceStatusId == ServiceStatusEnum.Draft")
      .d-flex
        button.button.button-primary.button-tdays(@click="confirmService")
          font-awesome-icon(:icon="['fas', 'check']")
        button.button.button-primary.button-tpics.ml-2(@click="declineService")
          font-awesome-icon(:icon="['fas', 'times']")
    td
      router-link.button.button-primary.button-tdays(:to="{ name: 'ServiceDetails', params: { serviceId: service.Id } }") öffnen

</template>

<script>
import { ServiceTypesEnum } from "@/utils/enums/serviceTypes/ServiceTypesEnum.ts";
import { ServiceStatusEnum } from "@/utils/enums/serviceStatus/ServiceStatusEnum.ts";
import EventBus from "@/event-bus";
import { ServicesComponent } from "@/lib/components/services/ServicesComponent.ts";
import { ServiceTypesComponent } from "@/lib/components/serviceTypes/ServiceTypesComponent.ts";

export default {
  name: "ServicesTableRow",
  props: {
    service: {
      type: Object,
      required: true,
    },
    loadServices: {
      type: Function,
      required: false,
    },
    loadServicesCount: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      serviceType: undefined,

      ServiceStatusEnum: ServiceStatusEnum,
      ServiceTypesEnum: ServiceTypesEnum,
    };
  },
  async mounted() {
    try {
      this.loadServiceType();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadServiceType() {
      try {
        const serviceTypesComponent = new ServiceTypesComponent();
        const serviceType = await serviceTypesComponent.getServiceType(
          this.$apollo,
          this.service.ServiceTypeId
        );
        if (!serviceType) {
          return;
        }
        this.serviceType = serviceType;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async confirmService() {
      try {
        const _service = JSON.parse(JSON.stringify(this.service));
        _service.ServiceStatusId = ServiceStatusEnum.Confirmed;
        _service.UpdatedBy = this.$session.get("userId");
        const savedService = await this._saveService(_service);
        if (!savedService) {
          return this.$alert("Die Leistung konnte nicht gespeichert werden");
        }
        if (savedService.Message) {
          savedService.Message
            ? this.$swal(savedService.Message)
            : this.$alert(savedService.Message);
        }
        if (savedService.IsSuccess) {
          if (this.loadServices) {
            this.loadServices();
          }
          if (this.loadServicesCount) {
            this.loadServicesCount();
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async declineService() {
      try {
        const _service = JSON.parse(JSON.stringify(this.service));
        _service.ServiceStatusId = ServiceStatusEnum.Declined;
        _service.UpdatedBy = this.$session.get("userId");
        const savedService = await this._saveService(_service);
        if (!savedService) {
          return this.$alert("Die Leistung konnte nicht gespeichert werden");
        }
        if (savedService.Message) {
          savedService.Message
            ? this.$swal(savedService.Message)
            : this.$alert(savedService.Message);
        }
        if (savedService.IsSuccess) {
          if (this.loadServices) {
            this.loadServices();
          }
          if (this.loadServicesCount) {
            this.loadServicesCount();
          }
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _saveService(service) {
      try {
        if (!service) {
          return {
            IsSuccess: false,
            Message: "Keine Leistung gefunden.",
          };
        }
        service.UpdatedBy = this.$session.get("userId");
        const servicesComponent = new ServicesComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedService = await servicesComponent.saveService(
          this.$apollo,
          service
        );
        EventBus.$emit("changeLoadingState", false);
        if (!savedService) {
          return {
            IsSuccess: false,
            Message: "Die Leistung konnte nicht gespeichert werden.",
          };
        }
        return savedService;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
