<template lang="pug">
.row
  template(v-if="showFilters")
    .col-12
      multi-select.form-control.rounded-0(
        :options="platforms"
        placeholder="Bitte wählen Sie Platformen aus"
        :selected-options="selectedPlatforms"
        @select="selectedPlatforms = $event"
      )

    .col-12.mt-2
      multi-select.form-control.rounded-0(
        :options="referrers"
        placeholder="Bitte wählen Sie Herkünfte aus"
        :selected-options="selectedReferrers"
        @select="selectedReferrers = $event"
      )
    .col-12.mt-2
      multi-select.form-control.rounded-0(
        :options="transactionTypes"
        placeholder="Bitte wählen Sie Zahlmethoden aus"
        :selected-options="selectedTransactionTypes"
        @select="selectedTransactionTypes = $event"
      )
    .col-12.mt-2
      multi-select.form-control.rounded-0(
        :options="shippingTypes"
        placeholder="Bitte wählen Sie Versandmethoden aus"
        :selected-options="selectedShippingTypes"
        @select="selectedShippingTypes = $event"
      )

    .col-12.mt-2
      multi-select.form-control.rounded-0(
        :options="orderStatus"
        placeholder="Bitte wählen Sie Auftragsstatus aus"
        :selected-options="selectedOrderStatus"
        @select="selectedOrderStatus = $event"
      )

    .col-3.mt-2
      .form-check
        input#days.form-check-input(:checked="displayUnit == 'days'" type='radio' name='' value='days' v-model="displayUnit")
        label.form-check-label(for='days') Tag
    .col-3.mt-2
      .form-check
        input#weeks.form-check-input(:checked="displayUnit == 'weeks'" type='radio' name='' value='weeks' v-model="displayUnit")
        label.form-check-label(for='weeks') Woche
    .col-3.mt-2
      .form-check
        input#months.form-check-input(:checked="displayUnit == 'months'" type='radio' name='' value='months' v-model="displayUnit")
        label.form-check-label(for='months') Monat
    .col-3.mt-2
      .form-check
        input#years.form-check-input(::checked="displayUnit == 'years'" type='radio' name='' value='years' v-model="displayUnit")
        label.form-check-label(for='years') Jahr
    .col-4
      label.form-label Von
      input.form-control(type='date' :value="from | formatInputDate" @change="from = ($event.target.value ? $event.target.value : null);")
    .col-4
      label.form-label Bis
      input.form-control(type='date' :value="to | formatInputDate" @change="to = ($event.target.value ? $event.target.value : null);")
    .col-4.d-flex.align-items-end
      button.button.button-primary.button-tdays.w-100(@click="loadItems") auswerten
  .col-12
    line-chart(:items="items" :from="moment(from).startOf(displayUnit).toDate()" :to="moment(to).endOf(displayUnit).toDate()" :display-unit="displayUnit")
  .col-12
    button.button.button-primary.button-tdays.w-100(@click="isDetailTableVisible = !isDetailTableVisible" v-if="items.length > 0") Detailtabelle öffnen
    evaluations-table(
      v-if="isDetailTableVisible"
      :dates="dates"
      :display-unit="displayUnit"
      :platformIds="platformIds"
      :referrerIds="referrerIds"
      :transactionTypeIds="transactionTypeIds"
      :shippingTypeIds="shippingTypeIds"
      :order-status-codes="orderStatusCodes"
      :items="items"
      :from="moment(from).startOf(displayUnit).toDate()"
      :to="moment(to).endOf(displayUnit).toDate()"
    )

</template>

<script>
import { Platforms } from "@/graphql/platforms/Platforms.ts";
import { Referrers } from "@/graphql/referrers/Referrers.ts";
import { OrderStatus } from "@/graphql/orderStatus/OrderStatus.ts";
import { TransactionTypes } from "@/graphql/transactionTypes/TransactionTypes.ts";
import { ShippingTypes } from "@/graphql/shippingTypes/ShippingTypes.ts";
import LineChart from "@/views/charts/LineChart.vue";
import { OrderStatusEnum } from "@/utils/enums/orderStatus/OrderStatusEnum.ts";
import EvaluationsTable from "@/views/evaluations/partials/EvaluationsTable.vue";
import moment from "moment";
import EventBus from "@/event-bus";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent.ts";

export default {
  name: "TotalSalesEvaluations",
  components: { EvaluationsTable, LineChart },
  props: {
    start: {
      type: Date,
      required: false,
    },
    end: {
      type: Date,
      required: false,
    },
    showFilters: {
      type: Boolean,
      required: false,
    },
    initLoading: {
      type: Boolean,
      required: false,
    },
    initDisplayUnit: {
      type: String,
      required: false,
      default: "months",
    },
  },
  data() {
    return {
      platforms: [],
      referrers: [],
      transactionTypes: [],
      shippingTypes: [],
      orderStatus: [],

      selectedPlatforms: [],
      selectedReferrers: [],
      selectedTransactionTypes: [],
      selectedShippingTypes: [],
      selectedOrderStatus: [],

      displayUnit: this.initDisplayUnit,
      from: this.start,
      to: this.end,

      dates: [],
      items: [],

      isDetailTableVisible: false,
      moment: moment,
    };
  },
  async mounted() {
    try {
      if (this.initLoading) {
        this.loadItems();
      }
      this.loadPlatforms();
      this.loadReferrers();
      this.loadTransactionTypes();
      this.loadShippingTypes();
      this.loadOrderStatus();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    async loadItems() {
      try {
        this.dates = [];
        this.items = [];
        const itemsComponent = new ItemsComponent();
        EventBus.$emit("changeLoadingState", true);
        const items = await itemsComponent.getItems(
          this.$apollo,
          undefined,
          undefined,
          undefined,
          undefined,
          moment(this.from).startOf(this.displayUnit).toDate(),
          moment(this.to).endOf(this.displayUnit).toDate(),
          this.platformIds,
          this.referrerIds,
          this.transactionTypeIds,
          this.shippingTypeIds,
          this.orderStatusCodes
        );
        EventBus.$emit("changeLoadingState", false);
        if (!items) {
          return;
        }
        this.items = items;
        this.dates = this.getDates();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadPlatforms() {
      try {
        const platforms = await this.$apollo
          .query({
            query: Platforms.Queries.Platforms,
          })
          .then(({ data }) => data?.platforms)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!platforms) {
          return this.$alert("Es konnten keine Platformen geladen werden.");
        }
        const _platforms = [];
        for (const platform of platforms) {
          const _platform = {
            value: platform.Id,
            text: platform.Name,
          };
          _platforms.push(_platform);
        }
        this.platforms = _platforms;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadReferrers() {
      try {
        const referrers = await this.$apollo
          .query({
            query: Referrers.Queries.Referrers,
          })
          .then(({ data }) => data?.referrers)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!referrers) {
          return this.$alert("Es konnten keine Herkünfte geladen werden.");
        }
        const _referrers = [];
        for (const referrer of referrers) {
          const _referrer = {
            value: referrer.ReferrerId,
            text: referrer.Name,
          };
          _referrers.push(_referrer);
        }
        this.referrers = _referrers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadTransactionTypes() {
      try {
        const transactionTypes = await this.$apollo
          .query({
            query: TransactionTypes.Queries.TransactionTypes,
          })
          .then(({ data }) => data?.transactionTypes)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!transactionTypes) {
          return this.$alert(
            "Es konnten keine Zahlungsmöglichkeiten geladen werden."
          );
        }
        const _transactionTypes = [];
        for (const transactionType of transactionTypes) {
          const _transactionType = {
            value: transactionType.Id,
            text: transactionType.Name,
          };
          _transactionTypes.push(_transactionType);
        }
        this.transactionTypes = _transactionTypes;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadShippingTypes() {
      try {
        const shippingTypes = await this.$apollo
          .query({
            query: ShippingTypes.Queries.ShippingTypes,
          })
          .then(({ data }) => data?.shippingTypes)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!shippingTypes) {
          return this.$alert(
            "Es konnten keine Versandmethoden geladen werden."
          );
        }
        const _shippingTypes = [];
        for (const shippingType of shippingTypes) {
          const _transactionType = {
            value: shippingType.Id,
            text: shippingType.Name,
          };
          _shippingTypes.push(_transactionType);
        }
        this.shippingTypes = _shippingTypes;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadOrderStatus() {
      try {
        const orderStatus = await this.$apollo
          .query({
            query: OrderStatus.Queries.OrderStatus,
          })
          .then(({ data }) => data?.orderStatus)
          .catch((e) => {
            console.log(e);
            return undefined;
          });
        if (!orderStatus) {
          return this.$alert("Es konnten keine Auftagsstatus geladen werden.");
        }
        const _orderStatus = [];
        for (const tempOrderStatus of orderStatus) {
          const _tempOrderStatus = {
            value: tempOrderStatus.StatusCode,
            text: tempOrderStatus.Definition,
          };
          _orderStatus.push(_tempOrderStatus);
        }
        this.orderStatus = _orderStatus;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    getDates() {
      try {
        if (!this.items) {
          return undefined;
        }
        if (this.items == 0) {
          return undefined;
        }
        const from = this.from;
        const to = this.to;
        const displayUnit = this.displayUnit;

        const dates = [];
        const difference = moment(to).diff(from, displayUnit) + 1;
        if (difference == 1) {
          dates.push(moment(from).toDate());
        } else {
          for (let i = 0; i < difference; i++) {
            dates.push(moment(from).add(i, displayUnit).toDate());
          }
        }
        return dates;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  computed: {
    platformIds() {
      try {
        if (!this.selectedPlatforms) {
          return undefined;
        }
        if (this.selectedPlatforms.length == 0) {
          return undefined;
        }
        const platformIds = [];
        this.selectedPlatforms.forEach((p) => platformIds.push(p.value));
        return platformIds;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    referrerIds() {
      try {
        if (!this.selectedReferrers) {
          return undefined;
        }
        if (this.selectedReferrers.length == 0) {
          return undefined;
        }
        const referrerIds = [];
        this.selectedReferrers.forEach((p) => referrerIds.push(p.value));
        return referrerIds;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    transactionTypeIds() {
      try {
        if (!this.selectedTransactionTypes) {
          return undefined;
        }
        if (this.selectedTransactionTypes.length == 0) {
          return undefined;
        }
        const transactionTypeIds = [];
        this.selectedTransactionTypes.forEach((p) =>
          transactionTypeIds.push(p.value)
        );
        return transactionTypeIds;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    shippingTypeIds() {
      try {
        if (!this.selectedShippingTypes) {
          return undefined;
        }
        if (this.selectedShippingTypes.length == 0) {
          return undefined;
        }
        const shippingTypeIds = [];
        this.selectedShippingTypes.forEach((p) =>
          shippingTypeIds.push(p.value)
        );
        return shippingTypeIds;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    orderStatusCodes() {
      try {
        if (!this.selectedOrderStatus) {
          return undefined;
        }
        if (this.selectedOrderStatus.length == 0) {
          return [
            OrderStatusEnum.Received,
            OrderStatusEnum.Paid,
            OrderStatusEnum.Completed,
          ];
        }
        const orderStatusCodes = [];
        this.selectedOrderStatus.forEach((p) => orderStatusCodes.push(p.value));
        return orderStatusCodes;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
