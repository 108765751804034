// @ts-ignore
import {ProductStatusAssignments} from "../../../graphql/productStatusAssignments/ProductStatusAssignments.ts";

export class ProductStatusAssignmentsComponent {
    async getProductStatusAssignment(apollo, productId, productStatusId, isDone) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productId) {
                return undefined;
            }
            if (!productStatusId) {
                return undefined;
            }
            const productStatusAssignment = await apollo.query({
                query: ProductStatusAssignments.Queries.ProductStatusAssignment,
                fetchPolicy: "no-cache",
                variables: {
                    productId: productId,
                    productStatusId: productStatusId,
                    isDone: isDone,
                }
            })
                .then(({data}) => data?.productStatusAssignment)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!productStatusAssignment) {
                return undefined;
            }
            return productStatusAssignment;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
