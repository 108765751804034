<template lang="pug">
  .table-responsive
    table.table.table-bordered
      thead
        tr
          th Datum
          th Gesamtumsatz
          th Shop-Umsatz
          th Ebay-Umsatz
          th touriDays
          th Post
          th Geschenkbox
          th Storno-Quote
          th Sales
          th Ø-Warenkorb
      tbody
        template(v-for="date in dates")
          evaluations-table-head-row(
            v-b-toggle="moment(date).format('DDMMYYYY')"
            :from="moment(date).startOf(displayUnit).toDate()"
            :to="moment(date).endOf(displayUnit).toDate()"
            :display-unit="displayUnit"
            :platform-ids="platformIds"
            :referrer-ids="referrerIds"
            :transaction-type-ids="transactionTypeIds"
            :shipping-type-ids="shippingTypeIds"
            :order-status-codes="orderStatusCodes"
          )
          evaluations-table-sub-table(
            :date="moment(date).toDate()"
            :display-unit="displayUnit"
            :platform-ids="platformIds"
            :referrer-ids="referrerIds"
            :transaction-type-ids="transactionTypeIds"
            :shipping-type-ids="shippingTypeIds"
            :order-status-codes="orderStatusCodes"
          )
        evaluations-table-head-row(
          v-if="dates.length > 1"
          :from="moment(from).startOf(displayUnit).toDate()"
          :to="moment(to).endOf(displayUnit).toDate()"
          :display-unit="displayUnit"
          :platform-ids="platformIds"
          :referrer-ids="referrerIds"
          :transaction-type-ids="transactionTypeIds"
          :shipping-type-ids="shippingTypeIds"
          :order-status-codes="orderStatusCodes"
          :is-sum-row="true"
        )

</template>

<script>
import EvaluationsTableHeadRow from "@/views/evaluations/partials/EvaluationsTableHeadRow.vue";
import EvaluationsTableSubTable from "@/views/evaluations/partials/EvaluationsTableSubTable.vue";
import moment from "moment";

export default {
  name: "EvaluationsTable",
  components: { EvaluationsTableSubTable, EvaluationsTableHeadRow },
  props: {
    dates: {
      type: Array,
      required: true,
    },
    displayUnit: {
      type: String,
      required: true,
    },
    platformIds: {
      type: Array,
      required: false,
    },
    referrerIds: {
      type: Array,
      required: false,
    },
    transactionTypeIds: {
      type: Array,
      required: false,
    },
    shippingTypeIds: {
      type: Array,
      required: false,
    },
    orderStatusCodes: {
      type: Array,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
    from: {
      type: Date,
      required: true,
    },
    to: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      moment: moment,
    };
  },
};
</script>

<style scoped></style>
