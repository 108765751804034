export class BrowserNotificationsComponent {
    async showNotification(message) {
        try {
            if (!message) {
                return;
            }
            if (!("Notification" in window)) {
                return;
            }
            if (Notification.permission === "denied") {
                return;
            }
            if (Notification.permission !== "granted") {
                await Notification.requestPermission();
            }
            if (Notification.permission !== "granted") {
                return;
            }
            new Notification(message, {
                icon: "https://s3-cloud.td-cdn.de/Images/makaira/touridat-fav32x32.ico",
            });
        } catch (e) {
            console.error(e);
        }
    }
}
