import gql from "graphql-tag";

export const NotificationComments = {
    Queries: {
        NotificationComments: gql`
        query notificationComments($notificationId: ID) {
            notificationComments(notificationId: $notificationId) {
                Id
                Comment
                CreatedAt
                CreatedBy
                NotificationId
            }
        }`,
    },
    Mutations: {
        CreateNotificationComment: gql`
        mutation createNotificationComment($notificationComment: NotificationCommentsInput) {
            createNotificationComment(notificationComment: $notificationComment) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
