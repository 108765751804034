import gql from "graphql-tag";

export const Mailboxes = {
    Queries: {
        MailboxByUserId: gql`
        query mailboxByUserId($userId: ID) {
            mailboxByUserId(userId: $userId) {
                Id
                Pop3Login
                Pop3Password
            }
        }`,
    }
}
