<template lang="pug">
  #calendar
    CalendarView.theme-default(
      :show-date="showDate"
      displayPeriodUom="week"
      :startingDayOfWeek="1"
      locale="de-DE"
      :items="selectedEvents"
      weekdayNameFormat="long"
      @input="setShowDate"
    )
      template(#header="{ headerProps }")
        CalendarViewHeader(
          @input="setShowDate"
          :header-props="headerProps"
          locale="de-DE"
        )
      template(#item="{ value: item, top }" v-if="events && Array.isArray(events) && events.length")
        .cv-item.cursor-pointer(
          :id="selectedEvents.indexOf(selectedEvents.find((e) => e.id == item.id)).toString()"
          :class="item.classes"
          :key="selectedEvents.indexOf(selectedEvents.find((e) => e.id == item.id)).toString()"
          :style="`top:calc(${top});${item.originalItem.style};${getHalfADayColor(item.originalItem)}`")
          span.m-3(:style="`color: ${getColor(item.originalItem.style)}`") {{ item.title }}
        b-tooltip(:target="selectedEvents.indexOf(selectedEvents.find((e) => e.id == item.id)).toString()" triggers="hover")
          b {{ item.title }}
          span(v-if="item.originalItem.HalfADay")
            br
            i Halber Tag
          template(v-if="moment(item.startDate).isSame(item.endDate)")
            p(v-if="moment(item.startDate).isSame(moment(item.startDate).startOf('day'))") {{ item.startDate | formatDate }}
            p(v-else-if="moment(item.startDate).isSame(item.endDate, 'day')") {{ item.startDate | formatDateTime }}
          p(v-else) {{ item.startDate | formatDate }} - {{ item.endDate | formatDate }}


</template>

<script>
import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import moment from "moment";

export default {
  name: "CalendarPlugin",
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showDate: new Date(),
      from: moment(this.showDate).startOf("week").add(1, "day").toDate(),
      to: moment(this.showDate).endOf("week").add(1, "day").toDate(),
      moment: moment,
    };
  },
  components: {
    CalendarView,
    CalendarViewHeader,
  },
  methods: {
    setShowDate(d) {
      try {
        this.showDate = d;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    getHalfADayColor(item) {
      if (item.HalfADay) {
        let hex = item.style.replace("background-color: ", "").replace(";", "");
        let color = this.getColor(item.style);
        if (color.includes("#0")) {
          return `background: linear-gradient(160deg, #eeeeee 50%, ${hex} 0%);`;
        } else {
          return `background: linear-gradient(160deg, #666666 50%, ${hex} 0%);`;
        }
      } else {
        return "";
      }
    },
    getColor(hex) {
      try {
        hex = hex.replace("background-color: ", "").replace(";", "");
        const rgb = this.hexToRgb(hex);
        if (rgb["r"] * 0.299 + rgb["g"] * 0.587 + rgb["b"] * 0.114 > 200) {
          return "#000000";
        } else {
          return "#ffffff";
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    hexToRgb(hex) {
      try {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : null;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  computed: {
    selectedEvents() {
      try {
        const events = this.events.filter(
          (e) =>
            (moment(e.startDate).isSameOrAfter(this.from) &&
              moment(e.startDate).isSameOrBefore(this.to)) ||
            (moment(e.startDate).isSameOrBefore(this.from) &&
              moment(e.endDate).isSameOrAfter(this.to))
        );
        return events;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  watch: {
    showDate() {
      try {
        this.from = moment(this.showDate)
          .startOf("week")
          .add(1, "day")
          .toDate();
        this.to = moment(this.showDate).endOf("week").add(1, "day").toDate();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style>
#calendar {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.cv-header {
  background-color: #c2c2c2;

  .currentPeriod {
    background-color: #333;
    color: #fff;
  }
}

.theme-default .cv-day.today {
  background-color: #ffc;
}

.cv-item {
  border-radius: 0 !important;
  margin-bottom: 0.5rem;

  div.clip {
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    height: 100%;
    clip-path: polygon(50% 0, 100% 0%, 100% 100%, 0% 100%);
    z-index: 0;
  }

  span {
    z-index: 1;
  }
}

.cv-item:hover {
  cursor: pointer;
  box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}
</style>
