<template lang="pug">
  .moloss-item-search-select-list-results-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'bed']")
    span {{ molossItem.id }}: {{ molossItem.Name }}

</template>

<script>
export default {
  name: "MolossItemSearchSelectListResultsListItem",
  props: {
    molossItem: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
