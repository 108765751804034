<template lang="pug">
.table-responsive
  table.table.table-bordered
    thead
      tr
        th(scope='col') Id
        th(scope='col') Vertragsnummer
        th(scope='col') Name
        th(scope='col') Variante(n)
        th(scope='col') Status
        th(scope='col') erstellt am
        th(scope='col') zuletzt aktualisiert
        th(scope='col') zuletzt aktualisiert von
        th(scope='col') Notiz
        th(scope='col') #
    tbody
      product-contracts-table-row(
        v-for="productContract in productContracts"
        :product-contract="productContract"
        :host-id="hostId"
        :key="productContract.Id"
      )

</template>

<script>
import ProductContractsTableRow from "@/views/productContracts/ProductContractsTableRow.vue";

export default {
  name: "ProductContractsTable",
  components: { ProductContractsTableRow },
  props: {
    productContracts: {
      type: Array,
      required: true,
    },
    hostId: {
      type: [String, Number],
      required: false,
    },
  },
};
</script>
