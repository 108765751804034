// @ts-ignore
import {UserGroupUsers} from "../../../graphql/userGroupUsers/UserGroupUsers.ts";

export class UserGroupUsersComponent {
    async createUserGroupUser(apollo, userGroupUser) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!userGroupUser) {
                return {
                    IsSuccess: false,
                    Message: "Kein Benutzer gefunden.",
                };
            }
            const createdUserGroupUser = await apollo.mutate({
                mutation: UserGroupUsers.Mutations.CreateUserGroupUser,
                variables: {
                    userGroupUser: userGroupUser,
                },
            })
                .then(({ data }) => data?.createUserGroupUser)
                .catch((e) => {
                    console.log(e);
                    return undefined;
                });
            if (!createdUserGroupUser) {
                return {
                    IsSuccess: false,
                    Message: "Der Benutzer konnte nicht erstellt werden.",
                };
            }
            return createdUserGroupUser;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async deleteUserGroupUser(apollo, userGroupUserId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!userGroupUserId) {
                return {
                    IsSuccess: false,
                    Message: "Kein Benutzer-Id gefunden.",
                };
            }
            const deletedUserGroupUser = await apollo.mutate({
                    mutation: UserGroupUsers.Mutations.DeleteUserGroupUser,
                    variables: {
                        id: userGroupUserId,
                    },
                })
                .then(({ data }) => data?.deleteUserGroupUser)
                .catch((e) => {
                    console.log(e);
                    return undefined;
                });
            if (!deletedUserGroupUser) {
                return {
                    IsSuccess: false,
                    Message: "Der Benutzer konnte nicht gelöscht werden.",
                };
            }
            return deletedUserGroupUser;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getUserGroupUsers(apollo, userGroupId) {
        try {
            if (!apollo || !userGroupId) {
                return undefined;
            }
            const userGroupUsers = await apollo
                .query({
                    query: UserGroupUsers.Queries.UserGroupUsers,
                    fetchPolicy: "no-cache",
                    variables: {
                        userGroupId: userGroupId,
                    },
                })
                .then(({ data }) => data?.userGroupUsers)
                .catch((e) => {
                    console.log(e);
                    return undefined;
                });
            return userGroupUsers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
