<template lang="pug">
  .container-fluid.p-5
    notification(
      :notification-id="notificationId"
      :host-id="hostId"
      :product-id="productId"
      :date="date"
      :redirect="redirect"
    )

</template>

<script>
import Notification from "@/views/notifications/partials/Notification.vue";
import NotificationsModal from "@/views/components/notifications/NotificationsModal.vue";

export default {
  name: "NotificationDetailsModal",
  components: { Notification },
  props: {
    notificationId: {
      type: String,
      required: false,
    },
    hostId: {
      type: String,
      required: false,
    },
    productId: {
      type: String,
      required: false,
    },
    date: {
      type: Date,
      required: false,
    },
  },
  methods: {
    redirect() {
      try {
        this.$emit("close");
        this.$modal.show(
          NotificationsModal,
          {
            productId: this.productId,
            hostId: this.hostId,
            createdBy: this.$session.get("userId"),
          },
          {
            height: "auto",
            classes: ["rounded-0", "w-auto"],
          }
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
