// @ts-ignore
import {Appointments} from "../../../graphql/appointments/Appointments.ts";

export class AppointmentsComponent {
    async getAppointments(apollo, createdBy, userId, filter, from, to, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const appointments = await apollo.query({
                query: Appointments.Queries.Appointments,
                fetchPolicy: "no-fetch",
                variables: {
                    createdBy: createdBy,
                    userId: userId,
                    filter: filter,
                    from: from,
                    to: to,
                    offset: offset,
                    limit: limit,
                },
            })
                .then(({data}) => data?.appointments)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!appointments) {
                return undefined;
            }
            return appointments;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getAppointmentsCount(apollo, createdBy, userId, filter, from, to) {
        try {
            if (!apollo) {
                return undefined;
            }
            const appointmentsCount = await apollo.query({
                query: Appointments.Queries.AppointmentsCount,
                fetchPolicy: "no-fetch",
                variables: {
                    createdBy: createdBy,
                    userId: userId,
                    filter: filter,
                    from: from,
                    to: to,
                },
            })
                .then(({data}) => data?.appointmentsCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (appointmentsCount == undefined) {
                return undefined;
            }
            return appointmentsCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getAppointment(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const appointment = await apollo.query({
                query: Appointments.Queries.Appointment,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.appointment)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!appointment) {
                return undefined;
            }
            return appointment;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async saveAppointment(apollo, appointment) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!appointment) {
                return {
                    IsSuccess: false,
                    Message: `Keinen Termin gefunden.`,
                };
            }
            const savedAppointment = await apollo.mutate({
                mutation: Appointments.Mutations.SaveAppointment,
                fetchPolicy: "no-cache",
                variables: {
                    appointment: appointment,
                }
            })
                .then(({data}) => data.saveAppointment)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!savedAppointment) {
                return {
                    IsSuccess: false,
                    Message: `Der Termin konnte nicht gespeichert werden.`,
                };
            }
            return savedAppointment;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
