import gql from "graphql-tag";

export const ProductStatusAssignments = {
    Queries: {
        ProductStatusAssignment: gql`
        query productStatusAssignment($productId: ID, $productStatusId: ID, $isDone: Boolean) {
            productStatusAssignment(productId: $productId, productStatusId: $productStatusId, isDone: $isDone) {
                Id
                ProductId
                ProductStatusId
                Note
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
                IsDone
            }
        }`,
    },
};
