import gql from "graphql-tag";

export const Appointments = {
    Queries: {
        Appointment: gql`
        query appointment($id: ID) {
            appointment(id: $id) {
                Id
                Name
                Description
                From
                To
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
            }
        }`,
        Appointments: gql`
        query appointments($createdBy: ID, $userId: ID, $filter: String, $from: Date, $to: Date, $offset: Int, $limit: Int) {
            appointments(createdBy: $createdBy, userId: $userId, filter: $filter, from: $from, to: $to, offset: $offset, limit: $limit) {
                Id
                Name
                Description
                From
                To
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
            }
        }`,
        AppointmentsCount: gql`
        query appointmentsCount($createdBy: ID, $userId: ID, $filter: String, $from: Date, $to: Date) {
            appointmentsCount(createdBy: $createdBy, userId: $userId, filter: $filter, from: $from, to: $to)
        }`,
    },
    Mutations: {
        SaveAppointment: gql`
        mutation saveAppointment($appointment: AppointmentsInput) {
            saveAppointment(appointment: $appointment) {
                IsSuccess
                Message
                StringData
            }
        }`,
    }
};
