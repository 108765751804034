import gql from "graphql-tag";

export const ProductVariants = {
    Queries: {
        ProductVariants: gql`
        query productVariants($productDraftId: ID) {
            productVariants(productDraftId: $productDraftId) {
                Id
                Guid
                ProductId
                ProductDraftId
                ProductVariantTypeId
                MinimumSellingPrice
                StartingPrice
                Nights
                MinimumSellingStock
                MinimumDealSellingStock
                MinimumDealSellingPrice
                CreatedAt
                UpdatedAt
                Stock
                ProductVariantNumber
            }
        }`,
        ProductVariant: gql`
        query productVariant($id: ID) {
            productVariant(id: $id) {
                Id
                Guid
                ProductId
                ProductDraftId
                ProductVariantTypeId
                MinimumSellingPrice
                StartingPrice
                Nights
                MinimumSellingStock
                MinimumDealSellingStock
                MinimumDealSellingPrice
                CreatedAt
                UpdatedAt
                Stock
                ProductVariantNumber
            }
        }`,
        ProductVariantNumber: gql`
        query productVariantNumber($productDraftId: ID) {
            productVariantNumber(productDraftId: $productDraftId)
        }`,
        ProductVariantsByProductContractId: gql`
        query productVariantsByProductContractId($productContractId: ID) {
            productVariantsByProductContractId(productContractId: $productContractId) {
                Id
                Guid
                ProductId
                ProductDraftId
                ProductVariantTypeId
                MinimumSellingPrice
                StartingPrice
                Nights
                MinimumSellingStock
                MinimumDealSellingStock
                MinimumDealSellingPrice
                CreatedAt
                UpdatedAt
                Stock
                ProductVariantNumber
            }
        }`,
    },
    Mutations: {
        SaveProductVariant: gql`
        mutation saveProductVariant($productVariant: ProductVariantsInput) {
            saveProductVariant(productVariant: $productVariant) {
                IsSuccess
                Message
                StringData
            }
        }`,
        DeleteProductVariant: gql`
        mutation deleteProductVariant($id: ID) {
            deleteProductVariant(id: $id) {
                IsSuccess
                Message
            }
        }`,
        CopyProductVariant: gql`
        mutation copyProductVariant($productVariantId: ID, $productDraftId: ID) {
            copyProductVariant(productVariantId: $productVariantId, productDraftId: $productDraftId) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
