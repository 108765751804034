<template lang="pug">
host-editing-progress-card-product-intermediate-status-assignment(
  v-if="productIntermediateStatusAssignment"
  :product-intermediate-status="productIntermediateStatus"
  :product-intermediate-status-assignment="productIntermediateStatusAssignment"
)


</template>

<script>
import { ProductIntermediateStatusAssignmentStatusEnum } from "@/utils/enums/productIntermediateStatusAssignmentStatus/productIntermediateStatusAssignmentStatusEnum.ts";
import HostEditingProgressCardProductIntermediateStatusAssignment from "@/views/host/hostEditing/HostEditingProgressCardProductIntermediateStatusAssignment.vue";

export default {
  name: "HostEditingProgressCardProductIntermediateStatus",
  components: { HostEditingProgressCardProductIntermediateStatusAssignment },
  props: {
    productIntermediateStatus: {
      type: Object,
      required: true,
    },
    productIntermediateStatusAssignments: {
      type: Array,
      required: true,
    },
  },
  computed: {
    productIntermediateStatusAssignment() {
      try {
        const productIntermediateStatusAssignments =
          this.productIntermediateStatusAssignments.filter(
            (p) =>
              p.ProductIntermediateStatusId == this.productIntermediateStatus.Id
          );
        if (!productIntermediateStatusAssignments) {
          return undefined;
        }
        const done = productIntermediateStatusAssignments.find(
          (p) =>
            p.ProductIntermediateStatusAssignmentStatusId ==
            ProductIntermediateStatusAssignmentStatusEnum.Completed
        );
        if (done) {
          return done;
        }
        const inProgress = productIntermediateStatusAssignments.find(
          (p) =>
            p.ProductIntermediateStatusAssignmentStatusId ==
            ProductIntermediateStatusAssignmentStatusEnum.InProgress
        );
        if (inProgress) {
          return inProgress;
        }
        const draft = productIntermediateStatusAssignments.find(
          (p) =>
            p.ProductIntermediateStatusAssignmentStatusId ==
            ProductIntermediateStatusAssignmentStatusEnum.Draft
        );
        if (draft) {
          return draft;
        }
        return undefined;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
