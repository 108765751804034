// @ts-ignore
import {Mailboxes} from "../../../graphql/mailboxes/Mailboxes.ts";

export class MailboxesComponent {
    async getMailboxByUserId(apollo, userId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!userId) {
                return undefined;
            }
            const mailbox = await apollo.query({
                query: Mailboxes.Queries.MailboxByUserId,
                variables: {
                    userId: userId,
                },
            })
                .then(({data}) => data?.mailboxByUserId)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!mailbox) {
                return undefined;
            }
            return mailbox;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
