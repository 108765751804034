export default () => {
  switch (process.env.NODE_ENV) {
    default:
      return {
        server: {
          host: "localhost",
          port: 5000,
        },
        graphQl: {
          uri: "http://localhost:5225/graphql",
        },
      };
    case "staging":
      return {
        server: {
          host: "0.0.0.0",
          port: 5000,
        },
        graphQl: {
          uri: "https://apollo-dev.hub-01.touri.dev/graphql",
        },
      };
    case "production":
      return {
        server: {
          host: "0.0.0.0",
          port: 5000,
        },
        graphQl: {
          uri: "https://graphql-apollo.htzn-cap-01.touri.dev/graphql",
        },
      };
  }
};
