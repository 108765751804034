// @ts-ignore
import {Addresses} from "../../../graphql/addresses/Addresses.ts";
// @ts-ignore
import {EmailValidatorComponent} from "../../../utils/components/EmailValidatorComponent.ts";

export class AddressesComponent {
  async getAddress(apollo, addressId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!addressId) {
        return undefined;
      }
      const address = await apollo
        .query({
          query: Addresses.Queries.Address,
          variables: {
            id: addressId,
          },
        })
        .then(({ data }) => data?.address)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!address) {
        return undefined;
      }
      return address;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getAddressesByEmail(apollo, email) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!email) {
        return undefined;
      }
      const emailValidatorComponent = new EmailValidatorComponent();
      const isEmailValid = emailValidatorComponent.validateEmail(email?.trim()?.toLowerCase());
      if (!isEmailValid) {
        return undefined;
      }
      const addresses = await apollo.query({
        query: Addresses.Queries.AddressesByEmail,
        variables: {
          email: email,
        },
      })
          .then(({data}) => data?.addressesByEmail)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!addresses) {
        return undefined;
      }
      return addresses;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getAddresses(apollo, filter, offset, limit) {
    try {
      if (!apollo) {
        return undefined;
      }
      const addresses = await apollo.query({
        query: Addresses.Queries.Addresses,
        variables: {
          filter: filter,
          offset: offset,
          limit: limit,
        },
      })
          .then(({data}) => data?.addresses)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
      if (!addresses) {
        return undefined;
      }
      return addresses;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}
