<template lang="pug">
.row
  .col-4
    span Id: {{ order.Id }}
    br
    span Bestellnummer: {{ order.OrderId }}
    br
    span Gutscheine:&nbsp;
    ul(v-if="vouchers")
      li(v-for="voucher in vouchers") {{ voucher.Id }}
    span(v-else) Lädt...
  .col-8
    span Plattform: {{ getPlatform() }}
    br
    span Bestelldatum: {{ order.OrderDate | formatDate }}
    br
    span Zahlart: {{ getPaymentType() }}
    br
    span Gesamtkosten: {{ order.TotalInvoiceGross | formatEuro }}
    br
    span Bezahlt am: {{ order.PaidAt | formatDateTime }}
    br
    span Käufer:&nbsp;
    span(v-if="address") {{ address.FirstName }} {{ address.LastName }} [{{ address.City }}]
    span(v-else) Lädt...
    br
    span E-Mail:&nbsp;
    span(v-if="address") {{ address.Email }}
    span(v-else) Lädt...

</template>

<script>
import { AddressesComponent } from "@/lib/components/addresses/AddressesComponent.ts";
import { VouchersComponent } from "@/lib/components/vouchers/VouchersComponent.ts";
import { PlatformsEnum } from "@/utils/enums/platforms/PlatformsEnum.ts";
import { PaymentTypesEnum } from "@/utils/enums/paymentTypes/PaymentTypesEnum.ts";

export default {
  name: "OrderSearchSelectListResultsListItem",
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vouchers: undefined,
      address: undefined,
    };
  },
  async mounted() {
    try {
      this.loadVouchers();
      this.loadAddress();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadVouchers() {
      try {
        const vouchersComponent = new VouchersComponent();
        const vouchers = await vouchersComponent.getVouchersByOrderId(
          this.$apollo,
          this.order.Id
        );
        if (!vouchers) {
          return;
        }
        this.vouchers = vouchers;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddress() {
      try {
        const addressesComponent = new AddressesComponent();
        const address = await addressesComponent.getAddress(
          this.$apollo,
          this.order.BillingAddressId
        );
        if (!address) {
          return;
        }
        this.address = address;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    getPlatform() {
      try {
        if (!this.order) {
          return undefined;
        }
        if (this.order.PlatformId == PlatformsEnum.Afterbuy) {
          return "eBay";
        } else if (this.order.PlatformId == PlatformsEnum.Plentymarkets) {
          return "Plentymarkets";
        } else if (this.order.PlatformId == PlatformsEnum.Makaira) {
          return "Moloss";
        } else if (this.order.PlatformId == PlatformsEnum.Internal) {
          return "Intern";
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    getPaymentType() {
      try {
        if (!this.order) {
          return undefined;
        }
        if (this.order.PaymentTypeId == PaymentTypesEnum.Transfer) {
          return "Überweisung";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.Paypal) {
          return "PayPal";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.Klarna) {
          return "Klarna";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.Ebay) {
          return "eBay";
        } else if (this.order.PaymentTypeId == PaymentTypesEnum.AmazonPay) {
          return "AmazonPay";
        }
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
