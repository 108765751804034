import gql from "graphql-tag";

export const Timebutler = {
  Queries: {
    getAbsencesForCalendarView: gql`
        query Timebutler_getAbsences($year: Int) {
            Timebutler_getAbsences(year: $year) {
                ID
                UserID
                From
                To
                HalfADay
                Morning
                EmployeeNumber
                ExtraVacationDay
                State
                SubstituteState
                Workdays
                Hours
                UserIDOfSubstitute
                MedicalCertificate
                Type {
                    ID
                    Name
                    Active
                    ReduceWorkingTime
                    HalfADay
                    Color
                }
                User {
                    ID
                    LastName
                    FirstName
                    EmployeeNumber
                    Email
                    Phone
                    MobilePhone
                    CostCenter
                    BranchOffice
                    Department
                    UserType
                    Language
                    Manager
                    Locked
                    AdditionalInformation
                    EntryDate
                    SeparationDate
                    Birthday
                }
            }
        }`,
  },
};
