import gql from "graphql-tag";

export const Callbacks = {
    Queries: {
        Callbacks: gql`
        query callbacks($isDone: Boolean, $offset: Int, $limit: Int) {
            callbacks(isDone: $isDone, offset: $offset, limit: $limit) {
                Id
                Name
                Phone
                VoucherId
                HostId
                Reason
                CallbackTo
                CreatedBy
                CreatedAt
                DoneAt
                DoneBy
            }
        }`,
        CallbacksCount: gql`
        query callbacksCount($isDone: Boolean) {
            callbacksCount(isDone: $isDone)
        }`,
        Callback: gql`
        query callback($id: ID) {
            callback(id: $id) {
                Id
                Name
                Phone
                VoucherId
                HostId
                Reason
                CallbackTo
                CreatedBy
                CreatedAt
                DoneAt
                DoneBy
            }
        }`,
    },
    Mutations: {
        SaveCallback: gql`
        mutation saveCallback($callback: CallbacksInput) {
            saveCallback(callback: $callback) {
                IsSuccess
                Message
            }
        }`,
    },
};
