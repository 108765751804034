import gql from "graphql-tag";

export const ProductIntermediateStatus = {
    Queries: {
        ProductIntermediateStatus: gql`
        query productIntermediateStatus($offset: Int, $limit: Int, $productStatusId: ID, $isActive: Boolean) {
            productIntermediateStatus(offset: $offset, limit: $limit, productStatusId: $productStatusId, isActive: $isActive) {
                Id
                Name
                Description
                IsActive
                ProductStatusId
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
            }
        }`,
        ProductIntermediateStatusCount: gql`
        query productIntermediateStatusCount($productStatusId: ID, $isActive: Boolean) {
            productIntermediateStatusCount(productStatusId: $productStatusId, isActive: $isActive)
        }`,
        ProductIntermediateStatusById: gql`
        query productIntermediateStatusById($id: ID) {
            productIntermediateStatusById(id: $id) {
                Id
                Name
                Description
                IsActive
                ProductStatusId
                CreatedBy
                CreatedAt
                UpdatedBy
                UpdatedAt
            }
        }`,
    },
    Mutations: {
        SaveProductIntermediateStatus: gql`
        mutation saveProductIntermediateStatus($productIntermediateStatus: ProductIntermediateStatusInput) {
            saveProductIntermediateStatus(productIntermediateStatus: $productIntermediateStatus) {
                IsSuccess
                Message
            }
        }`,
    }
};
