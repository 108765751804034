// @ts-ignore
import {Vouchers} from "../../../graphql/vouchers/Vouchers.ts";

export class VouchersComponent {
    async cancelVoucher(apollo, voucherId, userId) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!voucherId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Gutschein-Id gefunden.`,
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Benutzer-Id gefunden.`,
                };
            }
            const cancelledVoucher = await apollo
                .mutate({
                    mutation: Vouchers.Mutations.CancelVoucher,
                    variables: {
                        voucherId: voucherId,
                        userId: userId,
                    },
                })
                .then(({data}) => data?.cancelVoucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!cancelledVoucher) {
                return {
                    IsSuccess: false,
                    Message: `Der Gutschein konnte nicht storniert werden.`,
                };
            }
            return cancelledVoucher;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async refundVoucher(apollo, voucherId, userId, refundAmount) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: `Kein Apollo-Client gefunden.`,
                };
            }
            if (!voucherId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Gutschein-Id gefunden.`,
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: `Keine Benutzer-Id gefunden.`,
                };
            }
            if (refundAmount == undefined) {
                return {
                    IsSuccess: false,
                    Message: `Kein Rückerstattungsbetrag gefunden.`,
                };
            }
            const refundedVoucher = await apollo
                .mutate({
                    mutation: Vouchers.Mutations.RefundVoucher,
                    variables: {
                        voucherId: voucherId,
                        userId: userId,
                        refundAmount: refundAmount,
                    },
                })
                .then(({data}) => data?.refundVoucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!refundedVoucher) {
                return {
                    IsSuccess: false,
                    Message: `Der Gutschein konnte nicht erstattet werden.`,
                };
            }
            return refundedVoucher;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }

    async getVouchersByOrderId(apollo, orderId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!orderId) {
                return undefined;
            }
            const vouchers = await apollo.query({
                query: Vouchers.Queries.OrderVouchers,
                fetchPolicy: "no-cache",
                variables: {
                    orderId: orderId,
                },
            })
                .then(({data}) => data?.orderVouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!vouchers) {
                return undefined;
            }
            return vouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getVouchersByItemId(apollo, itemId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!itemId) {
                return undefined;
            }
            const vouchers = await apollo.query({
                query: Vouchers.Queries.ItemVouchers,
                fetchPolicy: "no-cache",
                variables: {
                    itemId: itemId,
                },
            })
                .then(({data}) => data?.itemVouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!vouchers) {
                return undefined;
            }
            return vouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getVoucher(apollo, id, voucherId) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id && !voucherId) {
                return undefined;
            }
            const voucher = await apollo.query({
                query: Vouchers.Queries.Voucher,
                fetchPolicy: "no-cache",
                variables: {
                    id: id,
                    voucherId: voucherId,
                }
            })
                .then(({data}) => data?.voucher)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!voucher) {
                return undefined;
            }
            return voucher;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getVouchers(apollo, hostId, productId, bookingRequestId, filter, offset, limit) {
        try {
            if (!apollo) {
                return undefined;
            }
            const vouchers = await apollo.query({
                query: Vouchers.Queries.Vouchers,
                fetchPolicy: "no-cache",
                variables: {
                    hostId: hostId,
                    productId: productId,
                    bookingRequestId: bookingRequestId,
                    filter: filter,
                    offset: offset,
                    limit: limit,
                }
            })
                .then(({data}) => data?.vouchers)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!vouchers) {
                return undefined;
            }
            return vouchers;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async getVouchersCount(apollo, hostId, productId, bookingRequestId, filter) {
        try {
            if (!apollo) {
                return undefined;
            }
            const vouchersCount = await apollo.query({
                query: Vouchers.Queries.VouchersCount,
                fetchPolicy: "no-cache",
                variables: {
                    hostId: hostId,
                    productId: productId,
                    bookingRequestId: bookingRequestId,
                    filter: filter,
                }
            })
                .then(({data}) => data?.vouchersCount)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (vouchersCount == undefined) {
                return undefined;
            }
            return vouchersCount;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }

    async sendGuestVoucherEmail(apollo, voucherId, userId, email) {
        try {
            if (!apollo) {
                return {
                    IsSuccess: false,
                    Message: "Kein Apollo-Client gefunden.",
                };
            }
            if (!voucherId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Gutschein-Id gefunden.",
                };
            }
            if (!userId) {
                return {
                    IsSuccess: false,
                    Message: "Keine Benutzer-Id gefunden.",
                };
            }
            if (!email) {
                return {
                    IsSuccess: false,
                    Message: "Keine E-Mail Adresse gefunden.",
                };
            }
            const sentGuestVoucherEmail = await apollo.mutate({
                mutation: Vouchers.Mutations.SendGuestVoucherEmail,
                fetchPolicy: "no-cache",
                variables: {
                    voucherId: voucherId,
                    userId: userId,
                    email: email,
                },
            })
                .then(({data}) => data?.sendGuestVoucherEmail)
                .catch((e) => {
                    console.error(e);
                    return {
                        IsSuccess: false,
                        Message: e.message,
                    };
                });
            if (!sentGuestVoucherEmail) {
                return {
                    IsSuccess: false,
                    Message: "Die Gutschein-Mail konnte nicht versendet werden.",
                };
            }
            return sentGuestVoucherEmail;
        } catch (e) {
            console.error(e);
            return {
                IsSuccess: false,
                Message: e.message,
            };
        }
    }
}
