<template lang="pug">
  tr
    td
      span(v-if="referrerId == undefined") Ebay
      span(v-else-if="referrer") {{ referrer.Name }}
    td {{ totalSalesValue | formatEuro }}
    td {{ touriDaysSalesValue | formatEuro }}
    td {{ postSalesQuantity }}
    td {{ boxSalesQuantity }}
    td {{ cancelQuote | formatPercentage }}
    td {{ totalSalesQuantity }} / {{ totalDealSalesQuantity }}
    td {{ averageShoppingBasket | formatEuro }}

</template>

<script>
import { PlatformsEnum } from "@/utils/enums/platforms/PlatformsEnum.ts";
import { ShippingTypesEnum } from "@/utils/enums/shippingTypes/ShippingTypesEnum.ts";
import { OrderStatusEnum } from "@/utils/enums/orderStatus/OrderStatusEnum.ts";
import { Orders } from "@/graphql/orders/Orders.ts";
import { Referrers } from "@/graphql/referrers/Referrers.ts";
import { ItemsComponent } from "@/lib/components/items/ItemsComponent.ts";

export default {
  name: "EvaluationsTableSubRow",
  props: {
    referrerId: {
      type: Number,
      required: false,
    },
    from: {
      type: Date,
      required: true,
    },
    to: {
      type: Date,
      required: true,
    },
    displayUnit: {
      type: String,
      required: true,
    },
    platformIds: {
      type: Array,
      required: false,
    },
    transactionTypeIds: {
      type: Array,
      required: false,
    },
    shippingTypeIds: {
      type: Array,
      required: false,
    },
    orderStatusCodes: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      referrer: undefined,
      items: [],
      postSalesItems: [],
      boxSalesItems: [],
      orders: [],
      ordersCount: 0,
      cancelledOrdersCount: 0,

      PlatformsEnum: PlatformsEnum,
      ShippingTypesEnum: ShippingTypesEnum,
    };
  },
  async mounted() {
    try {
      if (this.referrerId != undefined) {
        this.loadReferrer();
      }
      this.loadItems();
      this.loadPostSalesItems();
      this.loadBoxSalesItems();
      this.loadOrders();
      this.loadOrdersCount();
      this.loadCancelledOrdersCount();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadReferrer() {
      try {
        const referrer = await this.$apollo
          .query({
            query: Referrers.Queries.Referrer,
            variables: {
              referrerId: this.referrerId,
            },
          })
          .then(({ data }) => data?.referrer)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!referrer) {
          return this.$alert("Es konnte keine Herkunft geladen werden.");
        }
        this.referrer = referrer;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadItems() {
      try {
        const itemsComponent = new ItemsComponent();
        const items = await itemsComponent.getItems(
          this.$apollo,
          undefined,
          undefined,
          undefined,
          undefined,
          this.from,
          this.to,
          this.platformIds,
          this.referrerId != undefined ? [this.referrerId] : [null],
          this.transactionTypeIds,
          this.shippingTypeIds,
          this.orderStatusCodes
        );
        if (!items) {
          return;
        }
        this.items = items;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadOrders() {
      try {
        const orders = await this.$apollo
          .query({
            query: Orders.Queries.Orders,
            variables: {
              from: this.from,
              to: this.to,
              platformIds: this.platformIds,
              referrerIds:
                this.referrerId != undefined ? [this.referrerId] : [null],
              transactionTypeIds: this.transactionTypeIds,
              shippingTypeIds: this.shippingTypeIds,
              orderStatusCodes: this.orderStatusCodes,
            },
          })
          .then(({ data }) => data?.orders)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!orders) {
          return;
        }
        this.orders = orders;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadOrdersCount() {
      try {
        const ordersCount = await this.$apollo
          .query({
            query: Orders.Queries.OrdersCount,
            variables: {
              from: this.from,
              to: this.to,
              platformIds: this.platformIds,
              referrerIds:
                this.referrerId != undefined ? [this.referrerId] : [null],
              transactionTypeIds: this.transactionTypeIds,
              shippingTypeIds: this.shippingTypeIds,
            },
          })
          .then(({ data }) => data?.ordersCount)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (ordersCount == undefined) {
          return;
        }
        this.ordersCount = ordersCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadPostSalesItems() {
      try {
        const itemsComponent = new ItemsComponent();
        const items = await itemsComponent.getItems(
          this.$apollo,
          undefined,
          undefined,
          undefined,
          undefined,
          this.from,
          this.to,
          this.platformIds,
          [this.referrerId],
          this.transactionTypeIds,
          [ShippingTypesEnum.DesignEnvelopeBasic],
          this.orderStatusCodes
        );
        if (!items) {
          return;
        }
        this.postSalesItems = items;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBoxSalesItems() {
      try {
        const itemsComponent = new ItemsComponent();
        const items = await itemsComponent.getItems(
          this.$apollo,
          undefined,
          undefined,
          undefined,
          undefined,
          this.from,
          this.to,
          this.platformIds,
          [this.referrerId],
          this.transactionTypeIds,
          [ShippingTypesEnum.PresentBox],
          this.orderStatusCodes
        );
        if (!items) {
          return;
        }
        this.boxSalesItems = items;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadCancelledOrdersCount() {
      try {
        const ordersCount = await this.$apollo
          .query({
            query: Orders.Queries.OrdersCount,
            variables: {
              from: this.from,
              to: this.to,
              platformIds: this.platformIds,
              referrerIds:
                this.referrerId != undefined ? [this.referrerId] : [null],
              transactionTypeIds: this.transactionTypeIds,
              shippingTypeIds: this.shippingTypeIds,
              orderStatusCodes: [OrderStatusEnum.Cancelled],
            },
          })
          .then(({ data }) => data?.ordersCount)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (ordersCount == undefined) {
          return;
        }
        this.cancelledOrdersCount = ordersCount;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    getSalesValue(items, platformId) {
      try {
        if (!items) {
          return undefined;
        }
        if (items.length == 0) {
          return undefined;
        }
        if (platformId) {
          items = items.filter((i) => i.Order?.PlatformId == platformId);
        }
        let totalSales = 0;
        items.forEach(
          (i) =>
            (totalSales +=
              i.Quantity * i.SingleItemPriceGross - i.InsuranceAmount)
        );
        return totalSales;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    getTouriDaysValue(items) {
      try {
        if (!items) {
          return undefined;
        }
        if (items.length == 0) {
          return undefined;
        }
        let totalTouriDays = 0;
        items.forEach((i) => (totalTouriDays += i.InsuranceAmount));
        return totalTouriDays;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    getSalesQuantity(items, isDealSale) {
      try {
        if (!items) {
          return undefined;
        }
        if (items.length == 0) {
          return 0;
        }
        if (isDealSale != undefined) {
          items = items.filter((i) => i.IsDealSale == isDealSale);
        }
        let totalSalesQuantity = 0;
        items.forEach((i) => (totalSalesQuantity += i.Quantity));
        return totalSalesQuantity;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
  computed: {
    totalSalesValue() {
      try {
        return this.getSalesValue(this.items);
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    touriDaysSalesValue() {
      try {
        return this.getTouriDaysValue(this.items);
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    postSalesQuantity() {
      try {
        return this.getSalesQuantity(this.postSalesItems);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    boxSalesQuantity() {
      try {
        return this.getSalesQuantity(this.boxSalesItems);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    totalSalesQuantity() {
      try {
        return this.getSalesQuantity(this.items, false);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    totalDealSalesQuantity() {
      try {
        return this.getSalesQuantity(this.items, true);
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    cancelQuote() {
      try {
        if (this.ordersCount == 0) {
          return undefined;
        }
        if (this.cancelledOrdersCount == 0) {
          return undefined;
        }
        return (this.cancelledOrdersCount / this.ordersCount) * 100;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    averageShoppingBasket() {
      try {
        let val = 0;
        this.orders.forEach((o) => (val += o.TotalInvoiceGross));
        return val / this.orders.length;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
