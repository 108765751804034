import * as EmailValidator from "email-validator";

export class EmailValidatorComponent {
    validateEmail(email) {
        try {
            return EmailValidator.validate(email?.toString()?.trim());
        } catch (e) {
            console.log(e);
            return false;
        }
    }
}
