export const MailboxFoldersEnum = {
    Inbox: 1,
    InboxDeleted: 3,
    InboxSpam: 4,
    Outgoing: 5,
    OutgoingSent: 6,
    OutgoingDeleted: 8,
    OutgoingOutbox: 16,
    OutgoingException: 17,
    Templates: "templates",
}
