import gql from "graphql-tag";

export const Newsletters = {
    Queries: {
        Newsletters: gql`
        query newsletters($newsletterStatusIds: [ID], $filter: String, $offset: Int, $limit: Int) {
            newsletters(newsletterStatusIds: $newsletterStatusIds, filter: $filter, offset: $offset, limit: $limit) {
                Id
                Name
                Subject
                Headline
                SenderName
                SenderEmail
                AdditionalContent
                ApprovedAt
                ApprovedBy
                History
                CleverreachLog
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                AdvertisedFrom
                AdvertisedTill
                NewsletterTemplateId
                NewsletterStatusId
                NewsletterPlanningId
                ExternalId
            }
        }`,
        Newsletter: gql`
        query newsletter($id: ID) {
            newsletter(id: $id) {
                Id
                Name
                Subject
                Headline
                SenderName
                SenderEmail
                AdditionalContent
                ApprovedAt
                ApprovedBy
                History
                CleverreachLog
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                AdvertisedFrom
                AdvertisedTill
                NewsletterTemplateId
                NewsletterStatusId
                NewsletterPlanningId
                ExternalId
            }
        }`,
        NewslettersCount: gql`
        query newslettersCount($newsletterStatusIds: [ID], $filter: String) {
            newslettersCount(newsletterStatusIds: $newsletterStatusIds, filter: $filter)
        }`,
        NewsletterPreviewHtml: gql`
        query newsletterPreviewHtml($newsletterId: ID) {
            newsletterPreviewHtml(newsletterId: $newsletterId)
        }`,
        Cleverreach_getNLStats: gql`
        query Cleverreach_getNLStats($newsletterId: Int) {
            Cleverreach_getNLStats(newsletterId: $newsletterId)
        }
        `
    },
    Mutations: {
        SaveNewsletter: gql`
        mutation saveNewsletter($newsletter: NewslettersInput) {
            saveNewsletter(newsletter: $newsletter) {
                IsSuccess
                Message
            }
        }`,
        ApplyNewsletterPlanning: gql`
        mutation applyNewsletterPlanning($newsletterId: ID, $newsletterPlanningId: ID, $userId: ID) {
            applyNewsletterPlanning(newsletterId: $newsletterId, newsletterPlanningId: $newsletterPlanningId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        SyncNewsletter: gql`
        mutation syncNewsletter($newsletterId: ID, $userId: ID) {
            syncNewsletter(newsletterId: $newsletterId, userId: $userId) {
                IsSuccess
                Message
            }
        }`,
        CheckNewsletter: gql`
        mutation checkNewsletter($newsletterId: ID) {
            checkNewsletter(newsletterId: $newsletterId) {
                IsSuccess
                Message
            }
        }`,
    },
};
