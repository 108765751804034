<template lang="pug">
  b-card-body
    .row
      .col-12
        template(v-for="newsletterProduct in newsletterProducts" v-if="newsletterProduct.Product && newsletterProduct.Host")
          p(v-if="moment(newsletterProduct.Product.LiveShoppingStart).isSameOrBefore(newsletter.AdvertisedFrom) && moment(newsletterProduct.Product.LiveShoppingEnde).isSameOrAfter(newsletter.AdvertisedTill)") {{ newsletterProduct.Host.Name }} ({{ newsletterProduct.Product.Uebernachtungen }} Tage für {{ newsletterProduct.Product.DealPreisLiveShopping | formatEuro }} / Sonderpreis)
          p(v-else) {{ newsletterProduct.Host.Name }} ({{ newsletterProduct.Product.Uebernachtungen }} Tage für {{ newsletterProduct.Product.StartPreisFestPreis | formatEuro }})

</template>

<script>
import NewsletterProductsComponent from "@/lib/components/newsletterProducts/NewsletterProductsComponent";
import { AdvertisingTypesEnum } from "@/utils/enums/advertisingTypes/AdvertisingTypesEnum.ts";
import moment from "moment";
import { ProductsComponent } from "@/lib/components/products/ProductsComponent.ts";
import { HostsComponent } from "@/lib/components/hosts/HostsComponent.ts";

export default {
  name: "NewsletterCollapseBody",
  props: {
    newsletter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      newsletterProducts: [],

      moment: moment,
    };
  },
  async mounted() {
    try {
      this.loadNewsletterProducts();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadNewsletterProducts() {
      try {
        const newsletterProductsComponent = new NewsletterProductsComponent();
        const newsletterProducts =
          await newsletterProductsComponent.getNewsletterProducts(
            this.$apollo,
            this.newsletter.Id
          );
        if (!newsletterProducts) {
          return;
        }
        const productsComponent = new ProductsComponent();
        const hostsComponent = new HostsComponent();
        for (const newsletterProduct of newsletterProducts) {
          const product = await productsComponent.getProduct(
            this.$apollo,
            newsletterProduct.ProductId
          );
          if (!product) {
            continue;
          }
          newsletterProduct.Product = product;

          const host = await hostsComponent.getHost(
            this.$apollo,
            product.HostId
          );
          if (!host) {
            continue;
          }
          newsletterProduct.Host = host;
        }
        this.newsletterProducts = newsletterProducts;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    hasNewsletterAdvertisement(productAdvertisements) {
      try {
        if (!productAdvertisements) {
          return;
        }
        return productAdvertisements.some(
          (p) =>
            p.AdvertisementTypeId == AdvertisingTypesEnum.MondayNewsletter ||
            p.AdvertisementTypeId == AdvertisingTypesEnum.ThursdayNewsletter ||
            p.AdvertisementTypeId == AdvertisingTypesEnum.QuarterNewsletter ||
            p.AdvertisementTypeId == AdvertisingTypesEnum.TouriTippsNewsletter
        );
      } catch (e) {
        console.error(e);
        return false;
      }
    },
  },
};
</script>

<style scoped></style>
