<template lang="pug">
  .user-group-search-select-list-results-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'users']")
    span {{ userGroup.Name }}
    span(v-if="userGroup.Description") &nbsp;({{ userGroup.Description }})

</template>

<script>
export default {
  name: "UserGroupSearchSelectListResultsListItem",
  props: {
    userGroup: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
