<template lang="pug">
.accordion(v-if="newsletters" role="tablist")
  .row
    .col-md-12.col-lg-6(v-for="newsletter in newsletters" :key="newsletter.Id" :data-id="newsletter.Id")
      b-card.mb-1(no-body)
        b-card-header(header-tag="header" role="tab")
          a.p-1.h6(v-b-toggle="newsletter.Id" variant="link" @click="newsletter.IsVisible = !newsletter.IsVisible") {{ newsletter.Name }}
        b-collapse(:id="newsletter.Id" role="tabpanel" :visible="moment().isSameOrBefore(newsletter.AdvertisedTill)")
          newsletter-collapse-body(:newsletter="newsletter" v-if="newsletter.IsVisible")

</template>
<script>
import { NewsletterStatusEnum } from "@/utils/enums/newsletterStatus/NewsletterStatusEnum.ts";
import EventBus from "@/event-bus";
import NewsletterCollapseBody from "@/views/components/newsletters/NewsletterCollapseBody.vue";
import moment from "moment";
import { NewslettersComponent } from "@/lib/components/newsletters/NewslettersComponent.ts";

export default {
  name: "NewsletterCollapse",
  components: { NewsletterCollapseBody },
  data() {
    return {
      newsletters: [],
      moment: moment,
    };
  },
  mounted() {
    try {
      this.loadNewsletters();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadNewsletters() {
      try {
        const newslettersComponent = new NewslettersComponent();
        EventBus.$emit("changeLoadingState", true);
        const newsletters = await newslettersComponent.getNewsletters(
          this.$apollo,
          [NewsletterStatusEnum.Approved, NewsletterStatusEnum.Sent],
          undefined,
          undefined,
          10
        );
        EventBus.$emit("changeLoadingState", false);
        if (!newsletters) {
          return;
        }
        newsletters.forEach(
          (n) => (n.IsVisible = moment().isSameOrBefore(n.AdvertisedTill))
        );
        this.newsletters = newsletters;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>
<style scoped></style>
