<template lang="pug">
  .product-search-select-list-results-list-item
    font-awesome-icon.mr-2(:icon="['fas', 'bed']")
    span {{ product.Id }}

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'person']")
    span {{ product.Personen }} Person(en)

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'moon']")
    span {{ product.Uebernachtungen }} Nächte

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'euro']")
    span {{ product.SingleItemPriceGross | formatEuro }} / {{ product.DealPreisLiveShopping | formatEuro }}

</template>

<script>
export default {
  name: "ProductSearchSelectListResultsListItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped></style>
