<template lang="pug">
.row
  .col
    p
      font-awesome-icon.mr-2.red(:icon="['fas', 'circle']" v-if="productIntermediateStatusAssignment.ProductIntermediateStatusAssignmentStatusId == ProductIntermediateStatusAssignmentStatusEnum.Draft")
      font-awesome-icon.mr-2.orange(:icon="['fas', 'circle']" v-else-if="productIntermediateStatusAssignment.ProductIntermediateStatusAssignmentStatusId == ProductIntermediateStatusAssignmentStatusEnum.InProgress")
      font-awesome-icon.mr-2.green(:icon="['fas', 'circle']" v-else-if="productIntermediateStatusAssignment.ProductIntermediateStatusAssignmentStatusId == ProductIntermediateStatusAssignmentStatusEnum.Completed")
      span {{ productIntermediateStatus.Name }}
  template(v-if="productIntermediateStatusAssignment.ProductIntermediateStatusAssignmentStatusId != ProductIntermediateStatusAssignmentStatusEnum.Draft")
    .col
      p
        font-awesome-icon.mr-2.ml-2(:icon="['fas', 'user']")
        span(v-if="user") {{ user.FirstName }} {{ user.LastName }}
        span(v-else) lädt...
    .col
      p
        font-awesome-icon.mr-2(:icon="['fas', 'clock']")
        span {{ productIntermediateStatusAssignment.CreatedAt | formatDateTime }}
    .col-12(v-if="productIntermediateStatusAssignment.Note")
      p
        font-awesome-icon.mr-2.ml-2(:icon="['fas', 'comments']")
        span {{ productIntermediateStatusAssignment.Note }}

</template>

<script>
import { UsersComponent } from "@/lib/components/users/UsersComponent.ts";
import { ProductIntermediateStatusAssignmentStatusEnum } from "@/utils/enums/productIntermediateStatusAssignmentStatus/productIntermediateStatusAssignmentStatusEnum.ts";

export default {
  name: "HostEditingProgressCardProductIntermediateStatusAssignment",
  props: {
    productIntermediateStatus: {
      type: Object,
      required: true,
    },
    productIntermediateStatusAssignment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      user: undefined,
      ProductIntermediateStatusAssignmentStatusEnum:
        ProductIntermediateStatusAssignmentStatusEnum,
    };
  },
  async mounted() {
    try {
      this.loadUser();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadUser() {
      try {
        const usersComponent = new UsersComponent();
        const user = await usersComponent.getUser(
          this.$apollo,
          this.productIntermediateStatusAssignment.CreatedBy
        );
        if (!user) {
          return user;
        }
        this.user = user;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped>
.red {
  color: red;
}
.orange {
  color: orange;
}
.green {
  color: green;
}
</style>
