import gql from "graphql-tag";

export const Services = {
    Queries: {
        HolidayServices: gql`
        query holidayServices {
            holidayServices {
                Id
                Description
                CreatedAt
                UpdatedAt
                ServiceTypeId
            }
        }`,
        AdditionalHolidayServices: gql`
        query additionalHolidayServices {
            additionalHolidayServices {
                Id
                Description
                CreatedAt
                UpdatedAt
                ServiceTypeId
            }
        }`,
        Services: gql`
        query services(
            $offset: Float
            $limit: Float
            $filter: String
            $serviceStatus: [ID]
        ) {
            services(
                offset: $offset
                limit: $limit
                filter: $filter
                serviceStatus: $serviceStatus
            ) {
                Id
                Description
                ServiceTypeId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                ServiceStatusId
            }
        }`,
        Service: gql`
        query service(
            $id: ID
        ) {
            service(
                id: $id
            ) {
                Id
                Description
                ServiceTypeId
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                ServiceStatusId
            }
        }`,
        ServicesCount: gql`
        query servicesCount($filter: String, $serviceStatus: [ID]) {
            servicesCount(filter: $filter, serviceStatus: $serviceStatus)
        }`,
    },
    Mutations: {
        SaveService: gql`
        mutation saveService($service: ServicesInput) {
            saveService(service: $service) {
                IsSuccess
                Message
                StringData
            }
        }`,
    },
};
