<template lang="pug">
.order-search-select-list-item
  router-link(:to="{ name: 'OrderOverview', params: { orderId: order.Id } }" target="_blank")
    font-awesome-icon.mr-2.txt-white(:icon="['fa-brands', 'fa-amazon-pay']" v-if="order.PaymentTypeId == PaymentTypesEnum.AmazonPay")
    font-awesome-icon.mr-2.txt-white(:icon="['fa-solid', 'fa-k']" v-else-if="order.PaymentTypeId == PaymentTypesEnum.Klarna")
    font-awesome-icon.mr-2.txt-white(:icon="['fa-brands', 'fa-paypal']" v-else-if="order.PaymentTypeId == PaymentTypesEnum.Paypal")
    font-awesome-icon.mr-2.txt-white(:icon="['fa-solid', 'fa-money-bill']" v-else-if="order.PaymentTypeId == PaymentTypesEnum.Transfer")
    font-awesome-icon.mr-2.txt-white(:icon="['fa-brands', 'fa-ebay']" v-else-if="order.PaymentTypeId == PaymentTypesEnum.Ebay")
    span {{ order.Id }} [{{ orderStatus }}]

    template(v-if="invoiceAddress")
      font-awesome-icon.ml-2.mr-2(:icon="['fas', 'user']")
      span {{ invoiceAddress.FirstName }} {{ invoiceAddress.LastName }}
    span(v-else) Lädt...

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'clock']")
    span {{ order.OrderDate | formatDate }}

    font-awesome-icon.ml-2.mr-2(:icon="['fas', 'euro']")
    span {{ order.TotalInvoiceGross | formatEuro }}

  font-awesome-icon.ml-2(icon='trash' @click="deselect(order)" v-if="deselect")

</template>

<script>
import { AddressesComponent } from "@/lib/components/addresses/AddressesComponent.ts";
import { PaymentTypesEnum } from "@/utils/enums/paymentTypes/PaymentTypesEnum.ts";
import { OrderStatusEnum } from "@/utils/enums/orderStatus/OrderStatusEnum.ts";

export default {
  name: "OrderSearchSelectListItem",
  props: {
    order: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      invoiceAddress: undefined,
      host: undefined,

      PaymentTypesEnum: PaymentTypesEnum,
    };
  },
  async mounted() {
    try {
      this.loadInvoiceAddress();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadInvoiceAddress() {
      try {
        const addressesComponent = new AddressesComponent();
        const invoiceAddress = await addressesComponent.getAddress(
          this.$apollo,
          this.order.BillingAddressId
        );
        if (!invoiceAddress) {
          return;
        }
        this.invoiceAddress = invoiceAddress;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    orderStatus() {
      try {
        if (this.order.OrderStatusId == OrderStatusEnum.Received) {
          return "unbezahlt";
        } else if (this.order.OrderStatusId == OrderStatusEnum.Paid) {
          return "bezahlt";
        } else if (this.order.OrderStatusId == OrderStatusEnum.Completed) {
          return "abgeschlossen";
        } else if (this.order.OrderStatusId == OrderStatusEnum.Cancelled) {
          return "storniert";
        }
        return undefined;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped>
a {
  color: #fff !important;
}

a:hover {
  text-decoration: underline;
}
</style>
