// @ts-ignore
import {ProductIntermediateStatus} from "../../../graphql/productIntermediateStatus/ProductIntermediateStatus.ts";

export class ProductIntermediateStatusComponent {
    async getProductIntermediateStatus(apollo, productStatusId, isActive) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!productStatusId) {
                return undefined;
            }
            const productIntermediateStatus = await apollo.query({
                query: ProductIntermediateStatus.Queries.ProductIntermediateStatus,
                variables: {
                    productStatusId: productStatusId,
                    isActive: isActive,
                },
            })
                .then(({data}) => data?.productIntermediateStatus)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!productIntermediateStatus) {
                return undefined;
            }
            return productIntermediateStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
