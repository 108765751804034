<template lang="pug">
  .product-search-select-list-results-list-item
    router-link(:to="{ name: 'ProductDetails', params: { hostId: product.HostId, productId: product.Id } }" target="_blank")
      font-awesome-icon.mr-2(:icon="['fas', 'bed']")
      span {{ product.Id }}:

      font-awesome-icon.ml-2.mr-2(:icon="['fas', 'person']")
      span {{ product.Personen }} Person(en)

      font-awesome-icon.ml-2.mr-2(:icon="['fas', 'moon']")
      span {{ product.Uebernachtungen }} Nächte

      font-awesome-icon.ml-2.mr-2(:icon="['fas', 'euro']")
      span {{ product.SingleItemPriceGross | formatEuro }} / {{ product.DealPreisLiveShopping | formatEuro }}
    font-awesome-icon.ml-2(icon='trash' @click="deselect(product)" v-if="deselect")

</template>

<script>
export default {
  name: "ProductSearchSelectListItem",
  props: {
    product: {
      type: Object,
      required: true,
    },
    deselect: {
      type: Function,
      required: false,
    },
  },
};
</script>

<style scoped>
a {
  color: #fff !important;
}

a:hover {
  text-decoration: underline;
}
</style>
