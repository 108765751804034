// @ts-ignore
import {VoucherStatus} from "../../../graphql/voucherStatus/VoucherStatus.ts";

export class VoucherStatusComponent {
    async getVoucherStatusById(apollo, id) {
        try {
            if (!apollo) {
                return undefined;
            }
            if (!id) {
                return undefined;
            }
            const voucherStatus = await apollo.query({
                query: VoucherStatus.Queries.VoucherStatusById,
                variables: {
                    id: id,
                },
            })
                .then(({data}) => data?.voucherStatusById)
                .catch((e) => {
                    console.error(e);
                    return undefined;
                });
            if (!voucherStatus) {
                return undefined;
            }
            return voucherStatus;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
